import React from "react";
import FaqCollapse from '../component/FaqCollapse'
import { FAQ as FAQData } from '../assets/Data/FAQ'
import "./faq.css"
import NavBar from "../component/navBar";
import NavBarBottom from "../component/navbarBottom";
import {Col, Container, Row} from "react-bootstrap";
import Footer from "../component/footer";


function FAQ() {
  const handleFaqData = () => {
    return FAQData.map(({ q, a }, index) => {
      return (
          <FaqCollapse question={q} key={index}>
            {a}
          </FaqCollapse>
      )
    })
  }
  return (
      <div className="main">
          <NavBar />

          <section
              className="main-banner inner-banner about-banner"
              style={{
                  height: 250,
              }}
          >
              <Container>
                  <Row>
                      <Col>
                          <div className="banner-content">
                              <h1 className="h1-title">Frequently Asked Questions</h1>
                              <div className="breadcrumb-box">
                                  <ul>
                                      <li>
                                          <a href="/" title="home">
                                              Home
                                          </a>
                                      </li>
                                      <li>FAQs</li>
                                  </ul>
                              </div>
                          </div>
                      </Col>
                  </Row>
              </Container>
          </section>
          <Container>
              <Row className="d-flex ">
                  <Col lg={4} style={{marginTop: 90, paddingBottom:0}}>
                      <div className="banner-content" >
                          <h1 className="wel-h1">Got Any Questions?</h1>
                      </div>
                  </Col>
<Col lg={8}>
      <div className="faq-arrow" >
        {handleFaqData()}
      </div>
</Col>

              </Row>
          </Container>
          <NavBarBottom/>
          <Footer />
      </div>

  );
}

export default FAQ