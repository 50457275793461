import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../component/navBar";
import NavBarBottom from "../../component/navbarBottom";
import Footer from "../../component/footer";
import womenshealth from "../../assets/images/womens-health-services.jpg";
import "./healthservices.css";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';



function WomensHealth() {
    return (
        <div className="main">
            <title>pcp near me</title>
            <NavBar />

            <section
                className="main-banner inner-banner about-banner"
                style={{
                    height: 250,
                }}
            >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-content">
                                <title>pcp near me</title>
                                <h1 className="h1-title">Our Services</h1>
                                <div className="breadcrumb-box">
                                    <title>pcp near me</title>
                                    <ul>
                                        <li>
                                            <a href="/" title="home">
                                                Home
                                            </a>
                                        </li>
                                        <li>WOMEN'S HEALTH</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <!-- Sidebar Page Container --> */}
            <div class="sidebar-page-container">
                <title>pcp near me</title>
                <div class="auto-container">
                    <title>pcp near me</title>
                    <div class="row">
                        <title>pcp near me</title>
                        <div class="col-lg-4">
                            <title>pcp near me</title>
                            <aside class="sidebar">
                                {/* <!-- Category Widget --> */}
                                <div class="sidebar-widget-two categories-widget-two">
                                    <title>pcp near me</title>
                                    <div class="widget-content">
                                        <title>pcp near me</title>
                                        <h8 className="heading-8"> Our Services Include:</h8>
                                        <ul>
                                            <li> <ArrowRightIcon/>Annual physicals</li>
                                            <li><ArrowRightIcon/>Breast exam</li>
                                            <li><ArrowRightIcon/>Breast cancer screening</li>
                                            <li><ArrowRightIcon/>Lab tests</li>
                                            <li><ArrowRightIcon/>Chronic disease management</li>
                                            <li><ArrowRightIcon/>Colon cancer testing</li>
                                            <li><ArrowRightIcon/>Osteoporosis screening</li>
                                            <li><ArrowRightIcon/>Diagnostic testing evaluations</li>
                                            <li><ArrowRightIcon/>Smoking cessation</li>
                                            <li><ArrowRightIcon/>Birth control management </li>
                                            <li><ArrowRightIcon/>Family planning</li>
                                            <li><ArrowRightIcon/>Depression Screening</li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>

            <div className="col-lg-8">
                <title>pcp near me</title>
                <div className="services-details pl-lg-5">
                    <title>pcp near me</title>
                    <div className="image-box"><img src={womenshealth} style={{marginLeft: -8}} alt="women's health"/>
                        <title>pcp near me</title>
                    </div>
                    <div className="content">
                        <title>pcp near me</title>
                        <div className="sec-title">
                            <title>pcp near me</title>
                            <div className="sub-title">Women's Health</div>
                            <h2>Highly Personalized Care<br/> For Our Female Patients.</h2>
                        </div>
                        <div className="text">
                            <title>pcp near me</title>
                            Prioritizing your health - both physical and mental - has never been more important. Over the past few years, many women have put off taking care of their general health and wellness needs. They have adjusted their daily routines, including the way they connect with family and friends. The combination has led to serious health problems for some women.
                            Now is a great time for all women to focus on better health, especially those with underlying health conditions, such as hypertension, diabetes, obesity, cardiovascular and respiratory conditions.
                        </div>
                        <blockquote>
                            <div className="text" style={{fontSize:22}}><b>"</b>When Women Take Care Of Their Health, They Become Their Best Friend.<b>"</b>
                                <title>pcp near me</title>
                            </div>
                            <h4> —Maya Angelou</h4>
                        </blockquote>

                    </div>
                </div>
                    </div>
                </div>
            </div>
            </div>
            <NavBarBottom/>
            <Footer />
        </div>
    );
}

export default WomensHealth;