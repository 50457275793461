import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter} from "react-router-dom";
import { MyProvider } from "./myContext";


ReactDOM.render(
    <React.StrictMode>
    <MyProvider>
      <BrowserRouter>
        <App/>
      </BrowserRouter>
    </MyProvider>
    </React.StrictMode>,
  document.getElementById("root")
);
