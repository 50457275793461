import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./aboutUsC.css";
import opening from '../assets/images/opening.jpg'

function AboutUsC() {
  return (

    <section className="main-about-us">
      <Container>
        <Row className="d-flex align-items-center">
          <Col lg={6}>
            <div className="about-img-box">
              <title>family doctor</title>
              <img
                src={opening}
                alt="first lithonia medical center grand opening"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="about-content">
              <title>family doctor</title>
              <span className="sub-title">ABOUT US</span>
              <h2 className="h2-title">We Work With You To Address</h2>
              <div className="about-text">
                <title>family doctor</title>
                <p>
                  All your medical concerns. Are you still looking for a doctor near you? Look no further! When you choose First Lithonia
                  Medical Center to be your primary care provider, you will have access to us
                  24 hours, 7 days a week. Unlike many other PCP offices, our practice was
                  built to be part of your family. We care more! Our practice offers extended
                  hours, walk-ins, and same-day appointments. Whether it's your annual
                  check-up, managing your medications, or an urgent issue, our providers will
                  give you the quality care you deserve. As a family operated primary care
                  office, we truly value family first.
                </p>

              </div>
              <title>family doctor</title>
              <a href="https://www.firstlithonia.com/about-us" className="sec-btn">
                Read More
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  );
}

export default AboutUsC;
