import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import CHD from "../../assets/images/blog/chronicdiseases/first-lithonia-medical-center-comparison-of-normal-heart-and-coronary-artery-disease.png";
import "./blogdetails.css";
import "../blog.css"



function CoronaryHeart () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog"><img src={CHD} alt="lithonia-heart-doctor"/></div>

                    <div className="sec-title">
                        <h1>What Is Coronary Heart Disease?
                        </h1>
                    </div>
                    <div className="text"> Coronary heart disease or coronary artery disease is a common type of heart disease where the arteries in the heart struggle to deliver adequate oxygenated blood to the heart. The arteries struggle to deliver enough oxygenated blood because the arteries are clogged. The arteries get clogged when plaque (A mixture of cholesterol, calcium, fat and other substances) builds up in the heart. The plaque in the arteries can lead to blood clots, which obstruct blood flow and could eventually lead to a heart attack. Plaque can also cause chest pain or angina. The plaque buildup in the arteries causes the arteries to become narrow and harden overs the years which reduces the amount of oxygenated blood that reaches the heart. According to the centers for disease control and prevention, 18.2 million American adults suffer from coronary heart disease, making it the most common type of heart disease. Every year, about 366,000 people die from Coronary heart disease in the United States, whereas nearly 650,000 people in United States die from heart diseases in general. Coronary Micro vascular disease is a variation of coronary heart disease that affects the tiny arteries in the heart, this happens when the heart’s tiny vessels do not function properly. Coronary Micro vascular disease is more common in women. Coronary heart disease affects the coronary arteries on the surface on the heart.
                        <br/>
                        <br/>
                        There are several factors that can increase the risk of having a heart disease:
                        <br/>
                        <br/>

                        <b>1. AGE</b>: The risk of coronary heart disease increases around the age of 45. Before menopause, women have a lower risk of heart disease in comparison to men, but after menopause, around the age of 55 the risk increases for women, probably because they have less estrogen after menopause.
                        <br/>
                        <b>2. LIFESTYLE HABITS</b>: Having bad lifestyle habits can lead to plaque buildup in the arteries over time and increase the risk of getting coronary heart disease. Some of these lifestyle habits include: Not getting enough quality sleep, smoking or constant exposure to second hand smoking, being physically inactive, having unhealthy eating patterns, and stress.
                        <br/>
                        <b>3. ENVIRONMENT AND OCCUPATION</b>: Air pollution is known to increase the risk of having coronary heart disease. Having poor working conditions can also increase your risk of having coronary heart disease. Sitting for long periods of time, working more than 55 hours a week, having night shifts that affect your sleeping patterns, being exposed to toxins are all possible ways of increasing your risk of having coronary heart disease.
                        <br/>
                        <b>4. RACE</b>: According to NIH, Coronary heart disease is the leading cause of death for people of most racial and ethnic groups in the United States, including African Americans and whites.

                    </div>

                    <div className="text"> Coronary heart disease can be hard to diagnose because you might not experience any symptoms until you have a heart attack or a complication. Before having a heart attack you may experience symptoms like dizziness, Chest pain which could feel like burning, tightness, pressure in the chest, the pain may start from behind the breastbone, but can occur in the arms, throat back or shoulders. Other symptoms include neck pain, shortness of breath usually after a physical activity etc. When experiencing a heart attack, women are less likely to experience chest pain and would experience symptoms like extreme tiredness, stomach pain, pressure in the chest, nausea etc. If you experience chest pain and you don’t know if it is a heart attack or angina, call 9-1-1 immediately.
                    </div>
                    <div className="text">Prevention of Coronary heart disease starts with having a healthy lifestyle. By eating healthy, getting enough quality sleep, becoming physically active, stopping smoking and avoiding large exposure to second hand smoking, you can prevent the risk of having coronary disease and its complications.
                    </div>
                    <div className="text">In terms of diagnosis, your doctor can diagnose for coronary heart disease based on several factors such as your risk factors, your medical and family history. Your Doctor would check your risk factors to see analyze the risk of developing a coronary heart disease, Check for high blood pressure in your blood pressure readings, Calculate your BMI(body mass index) to see if your wealth is unhealthy and run blood tests to see if you have high blood cholesterol, diabetes or high blood triglycerides. Furthermore your health provider can use a risk calculator to analyze your risk of having a heart attack or dying from a heart related disease throughout your life.
                    </div>
                    <div className="text">Treatment for coronary heart disease depends on how serious your symptoms are and any other health conditions you have. If you are experiencing a heart attack for example, you may need emergency treatment. If your doctor or healthcare provider diagnoses you with coronary heart disease based on the tests carried out and your symptoms, your doctor would recommend adapting heart-healthy lifestyle procedures into your routine and prescribe medicine to prevent heart complications and the possibility of a heart attack. These lifestyle changes as stated in the prevention section could include eating healthy, becoming physically active, quitting smoking, getting enough quality sleep, managing stress properly are all ways one can live healthy. </div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-what-is-a-heart-attack"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-reduce-stress">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CoronaryHeart ;