import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import calorie from "../../assets/images/blog/chronicdiseases/calorie-deficit-first-lithonia-medical-center.jpg";
import "./blogdetails.css";
import "../blog.css"



function CalorieDeficit () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <title>black nutrition doctor</title>
                <div className="auto-container">
                    <title>black nutrition doctor</title>

                    <div className="image-box-blog"><img src={calorie} alt="lithonia-nutrition-doctor"/></div>
                    <title>black nutrition doctor</title>

                    <div className="sec-title">
                        <h1>How to Create a Calorie Deficit For Weight Loss
                        </h1>
                    </div>
                    <div className="text">
                        <title>black nutrition doctor</title>
                        Calories are the total number of calories, or &quot;energy,&quot; you get from all sources (carbohydrate,
                        fat, protein, and alcohol) in a serving of a food or beverage.

                        <br/>
                        <br/>

                        A calorie deficit is when a person burns more calories than they consume, which could help
                        them lose weight. According to the Centers for Disease Control and Prevention (CDC), a calorie
                        deficit can be created by eating fewer calories in your diet, exercising more, or a mixture of
                        both. The amount of calories that a person should consume to maintain their weight varies
                        according to their age, gender, and even activity levels. There are several ways to calculate how
                        many calories you use in a day. You can use an online calculator, you can multiply your weight
                        (lb.) by 15, or you can use the basic metabolic rate procedure to calculate their daily calorie
                        needs. But on average, according to the 2020–2025 dietary guidelines for Americans, females
                        should consume about 1600–2000 calories per day and males should consume about
                        2000–2400 calories per day to maintain their weight. 1 pound is about 3500 calories, meaning
                        that if you burn 3500 calories, you will lose 1 pound. So if you aim to create a daily calorie
                        deficit of 500 calories every day, in a week you will lose 1 pound. In order to lose weight at the
                        CDC-recommended pace of 1 to 2 pounds per week, it is recommended to cut about 500 to
                        1000 calories, respectively, from the daily quantity. Here are a few ways to create a calorie
                        deficit.

                    <br/>
                    <br/>

                        <b>1. EXERCISE MORE</b>: An important aspect of weight loss is exercise. A person who is not active
                        should try to increase their daily activity levels. These activities include walking, hiking,
                        biking, playing a recreational sport, etc. A person will burn more calories if they are
                        more physically active. As a result, they may require more calories, which may make
                        establishing a deficit simpler.
                        <br/>
                        <b>2. EAT LESS</b>: As discussed above, reducing your daily calorie intake over time can lead to
                        weight loss. A person should try to consume foods that are rich in nutrients but have
                        few calories. These types of foods include vegetables, grains, fruits, proteins, and
                        healthy oils. They might also want to drink more water, which will keep them hydrated
                        and replace any sugar-sweetened drinks they typically drink.
                        <br/>
                    </div>

                    <div className="text">
                        <title>black nutrition doctor</title>
                        Avoid decreasing calories too much. A person should not lose more than 2 pounds a week. If a
                        person reduces their calorie intake drastically, they won’t get the necessary nutrients and will
                        experience health problems. Some of these health problems include getting sick frequently, a
                        decrease in metabolism, and an increased risk of developing gallstones.</div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-reduce-stress"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-plant-based">Next Topic </a></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default CalorieDeficit;