import React from "react";
import "./servicesC.css";
import { Col, Container, Row } from "react-bootstrap";
import childhealth from "../assets/icon/firstlithoniachildhealth.jpg";
import geriatrichealth from "../assets/icon/firstlithoniageriatrichealth.jpeg";
import menshealth from "../assets/icon/firstlithoniamenshealth.jpg";
import womenshealth from "../assets/icon/firstlithoniawomenshealth.jpg";


function ServicesC() {
  return (
      <section className="main-our-services">
        <Container>
          <Row>
            <Col xs={12}>
              <div className="our-services-title">
                <title>pcp near me</title>
                <span className="sub-serv-title">What Services Do We Offer?</span>
                <h2 className="h2-serv-title">Our Services</h2>
              </div>
            </Col>
          </Row>
          <div className="our-services-list">
            <title>pcp near me</title>
            <Row>
              <Col xl={3} sm={6}>
                <div className="our-service-box">
                  <title>pcp near me</title>
                  <div className="our-service-text">
                    <title>pcp near me</title>
                    <div className="our-service-text-icon">
                      <title>pcp near me</title>
                      <a href={'/mens-health'} style={{color:"black"}}>
                       <img
                       src={menshealth}
                       alt="mens health"
                       />
                      </a>
                    </div>
                    <title> male pcp</title>
                    <h3 className="our-serv-icon-h3">Men's Health</h3>
                    <p className="our-serv-icon-p">
                      We know men usually don't like going to the doctor's office except when something is wrong. However, we encourage men to come in yearly for preventive visits so we can stay on top of any health concerns!
                    </p>
                    <a href="/mens-health" className="serv-btn" style={{textDecoration: "none", color:"white" }}>
                      Learn More
                    </a>
                  </div>
                </div>
              </Col>
              <Col xl={3} sm={6}>
                <div className="our-service-box">
                  <title>pcp near me</title>
                  <div className="our-service-text">
                    <title>pcp near me</title>
                    <div className="our-service-text-icon">
                      <title>pcp near me</title>
                      <a href={'/womens-health'} style={{color:"black"}}>
                       <img
                       src={womenshealth}
                       alt="women health"
                       />
                      </a>
                    </div>
                    <title> female pcp</title>
                    <h3 className="our-serv-icon-h3">Women's Health</h3>
                    <p className="our-serv-icon-p">
                      Here at First Lithonia Medical Center our team believes, prevention is better than the cure. That's why
                      we offer comprehensive care for women, with the primary goal of protecting and improving your health.
                    </p>
                    <a href="/womens-health" className="serv-btn" style={{textDecoration: "none", color:"white" }}>
                      Learn More
                    </a>
                  </div>
                </div>
              </Col>
              <Col xl={3} sm={6}>
                <div className="our-service-box">
                  <title>pcp near me</title>
                  <div className="our-service-text">
                    <title>pcp near me</title>
                    <div className="our-service-text-icon">
                      <title>pcp near me</title>
                      <a href={'/pediatric-health'} style={{color:"black"}}>
                        <img
                            src={childhealth}
                            alt="child health"
                        />
                      </a>
                    </div>
                    <h3 className="our-serv-icon-h3">Pediatric Health</h3>
                    <p className="our-serv-icon-p">
                      Yes, we see kids too! Our providers have decades of pediatric experience and training. What makes us so special is the fact we are a family oriented practice. We want to make sure your kids grow strong and healthy!
                    </p>
                    <a href="/pediatric-health" className="serv-btn" style={{textDecoration: "none", color:"white" }}>
                      Learn More
                    </a>
                  </div>
                </div>
              </Col>
              <Col xl={3} sm={6}>
                <div className="our-service-box">
                  <title>pcp near me</title>
                  <div className="our-service-text">
                    <title>pcp near me</title>
                    <div className="our-service-text-icon">
                      <title>pcp near me</title>
                      <a href={'/geriatric-health'} style={{color:"black"}}>
                        <img
                            src={geriatrichealth}
                            alt="geriatric health care"
                        />
                      </a>
                    </div>
                    <title> geriatric care doctor</title>
                    <h3 className="our-serv-icon-h3">Geriatric Health</h3>
                    <p className="our-serv-icon-p">
                      Our providers are experts in the diagnosis, treatment, and disease prevention of the elderly.
                      We understand the aging process is different for everyone, but the importance lies in the proper management of your health.
                    </p>
                    <a href="/geriatric-health" className="serv-btn" style={{textDecoration: "none", color:"white" }}>
                      Learn More
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

  );
}

export default ServicesC;
