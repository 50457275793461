import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../component/navBar";
import NavBarBottom from "../../component/navbarBottom";
import Footer from "../../component/footer";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import menshealth from "../../assets/images/mens-health-services.jpeg";



function MensHealth() {
    return (
        <div className="main">
            <NavBar />

            <section
                className="main-banner inner-banner about-banner"
                style={{
                    height: 250,
                }}
            >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-content">
                                <h1 className="h1-title">Our Services</h1>
                                <div className="breadcrumb-box">
                                    <ul>
                                        <li>
                                            <a href="/" title="home">
                                                Home
                                            </a>
                                        </li>
                                        <li>MEN'S HEALTH</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <!-- Sidebar Page Container --> */}
            <div class="sidebar-page-container">
                <div class="auto-container">
                    <div class="row">
                        <div class="col-lg-4">
                            <aside class="sidebar">
                                {/* <!-- Category Widget --> */}
                                <div class="sidebar-widget-two categories-widget-two">
                                    <div class="widget-content">
                                        <h8 className="heading-8"> Our Services Include:</h8>
                                        <ul>
                                            <li><ArrowRightIcon/>Annual physicals</li>
                                            <li><ArrowRightIcon/>Lab tests</li>
                                            <li><ArrowRightIcon/>Chronic disease management</li>
                                            <li><ArrowRightIcon/>Colon cancer testing</li>
                                            <li><ArrowRightIcon/>Osteoporosis screening</li>
                                            <li><ArrowRightIcon/>Diagnostic testing evaluations</li>
                                            <li><ArrowRightIcon/>Smoking cessation</li>
                                            <li><ArrowRightIcon/>Prostate Cancer Testing</li>
                                            <li><ArrowRightIcon/>Depression Screening</li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-lg-8">
                            <div className="services-details pl-lg-5">
                                <div className="image-box"><img
                                    src={menshealth}
                                    alt="men's health"
                                    />
                                </div>
                                <div className="content">
                                    <div className="sec-title">
                                        <div className="sub-title">Men's Health</div>
                                        <h2>Quality, Comprehensive Care<br/> For Our Male Patients.</h2>
                                    </div>
                                    <div className="text">New treatments and scientific breakthroughs that lead to cures characterize modern medicine. But as exciting and beneficial as many of these innovations are, none compares to the power of prevention. In addition to getting enough physical activity, ensuring healthy eating, and maintaining mental health, a fourth essential — and often overlooked — element in any comprehensive wellness strategy is keeping up with clinical preventive services.
                                    </div>
                                    <blockquote>
                                        <div className="text" style={{fontSize:20}}><b>"</b>Recognizing and preventing men's health problems is not just a man's issue. Because of its impact on wives, mothers, daughters, and sisters, men's health is truly a family issue.<b>"</b>
                                        </div>
                                        <h4> —Bill Richardson</h4>
                                    </blockquote>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       <NavBarBottom/>
            <Footer />
        </div>
    );
}

export default MensHealth;