import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import medicalinsurance from "../../assets/images/blog/health-insurance.jpg";
import "./blogdetails.css";
import "../blog.css"



function BlogPostMedicalInsurance () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog"><img src={medicalinsurance} alt="lithonia-medical-insurance"/></div>

                    <div className="sec-title">
                        <h1>The Importance of Having Health Insurance</h1>
                    </div>
                    <div className="text">Health insurance is a great thing to have because it protects you from unexpected, high medical costs. For example, a broken leg can cost up to $7,500. You pay less for covered in-network health care plus you’re entitled to a free preventative visit each year. </div>
                    <div className="text">Having full coverage health insurance is associated with lower death rates and better health outcomes. Choosing to not have health insurance puts your physical, mental, and financial health at risk. You will have improved access to care, positive health outcomes, and reduced financial stress. Having access to care will give you greater access to prescription drug therapy, as well as an early diagnosis and treatment which could be beneficial to your health.</div>
                    <div className="text">Together you will have a greater peace of mind knowing you are covered if medical treatment is needed.</div>
                        <div className="text"> Do you need more information about how to obtain medical insurance? <br/>
                        Visit <a href="https://www.healthcare.gov"> HealthCare.Gov </a> for more information about open enrollment dates.</div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-urgent-care"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-family-planning-birth-control">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default BlogPostMedicalInsurance ;