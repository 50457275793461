import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../component/navBar";
import Footer from "../component/footer";
import "./ContactUs.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'
import { MyContext } from "../myContext";
import NavBarBottom from "../component/navbarBottom";

function ContactUs() {
  const { setIsSubmenuOpen } = React.useContext(MyContext);

  return (
    <div className="main">
      <NavBar />

      <section onMouseOver={() => setIsSubmenuOpen("")}>
        <section
          className="main-banner inner-banner contact-banner"
          style={{
            height: 250,
          }}
        >
          <Container>
            <Row>
              <Col>
                <div className="banner-content">
                  <h1 className="h1-title">Contact Us</h1>
                  <div className="breadcrumb-box">
                    <ul>
                      <li>
                        <a href="/" title="home">
                          Home
                        </a>
                      </li>
                      <li>Contact Us</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <div className="contact-page-content">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xl={8} lg={9} className="contact-us-content">
                <span className="sub-title">Scheduling Appointments Just Got A Whole Lot Easier</span>
                <h4 className="h4-title">Book Online Now</h4>
                <button type="submit" className="sec-btn" style={{marginLeft: 285}} onClick href={"https://healow.com/apps/practice/first-lithonia-medical-center-lithonia-ga-21141?v=1"}>
                  <span>Book Now</span>
                </button>
              </Col>
              <Col>
                <div className="form-box">

                </div>
              </Col>
            </Row>

            {/* Contant page link */}
            <div className="contact-page-link">
              <Row className="d-flex justify-content-center">
                <Col lg={4} sm={6}>
                  <div className="contact-link-box">
                    <div className="contact-link-text">
                      <div className="contact-link-icon">
                        <a href={'tel:+7703236458'} style={{color:"black"}}>
                          <FontAwesomeIcon icon={solid ( 'phone')}/>
                        </a>
                      </div>
                      <h3 className="h3-title">Call Now</h3>
                      <p>
                        <a href="#" title="Call on 7703236458">
                         <b>770-323-6458</b>
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>

                <Col lg={4} sm={6}>
                  <div className="contact-link-box">
                    <div className="contact-link-text">
                      <div className="contact-link-icon">
                        <a href="https://www.google.com/maps/dir//first+lithonia+medical+center/@33.7167376,-84.1743833,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f5ac680dd50927:0x11d26d1ef90d849!2m2!1d-84.166525!2d33.7153554" style={{color:"black"}}>
                          <FontAwesomeIcon icon={solid ( 'map-marked')}/>
                        </a>
                      </div>
                      <h3 className="h3-title">Location</h3>
                      <p>
                        <a href="#" title="go to location">
                          <b>2505 Panola Rd Lithonia, Georgia 30058</b>
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>

                <Col lg={4} sm={6}>
                  <div className="contact-link-box">
                    <div className="contact-link-text">
                      <div className="contact-link-icon">
                        <a href={'mailto:'} style={{color:"black"}}>
                          <FontAwesomeIcon icon={solid ( 'at')}/>
                        </a>
                      </div>
                      <h3 className="h3-title">Email Address</h3>
                      <p>
                        <a href="mailto:" title="email-address">
                          <b>firstlithonia@gmail.com</b>
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Contact map */}
        <div className="contact-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2053.567866916503!2d-84.16598329741291!3d33.71593541559862!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5ac680dd50927%3A0x11d26d1ef90d849!2sFirst%20Lithonia%20Medical%20Center!5e0!3m2!1sen!2sus!4v1662765672931!5m2!1sen!2sus"
            width="100%"
            height="100%"
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact-page-empty-div"></div>
        <NavBarBottom/>
        <Footer />
      </section>
    </div>
  );
}

export default ContactUs;
