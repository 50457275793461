import React from "react";
import "./index.css";
import Home from "./pages/home";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import OkobahFoundation from "./pages/OkobahFoundation";
import ContactUs from "./pages/ContactUs";
import FAQ from "./pages/FAQ";
import PatientPortal from "./pages/PatientPortal";
import OfficePolicy from "./pages/OfficePolicy";
import Insurances from "./pages/Insurances";
import Services from "./pages/Services";
import { MyProvider } from "./myContext";
import BlogList from "./pages/BlogList";
import BlogPostUrgentCare from "./pages/blog-details/blog-postUrgentCare";
import MensHealth from "./pages/healthServices/menshealth";
import WomensHealth from "./pages/healthServices/womenshealth";
import PediatricHealth from "./pages/healthServices/pediatrichealth";
import GeriatricHealth from "./pages/healthServices/geriatrichealth";
import BlogPostMedicalInsurance from "./pages/blog-details/blog-postImportanceOfInsurance";
import BlogPostBirthControl from "./pages/blog-details/blog-postFamilyPlanning";
import BlogPostAnnualPhysical from "./pages/blog-details/blog-postAnnualPhysicals";
import BlogPostSTDtesting from "./pages/blog-details/blog-postSTD";
import BreastCancerAwareness from "./pages/blog-details/blog-postBreastCancerAwareness";
import HeartAttack from "./pages/blog-details/blog-postHeartAttack";
import CoronaryHeart from "./pages/blog-details/blog-postCoronaryHeartDisease";
import ReduceStress from "./pages/blog-details/blog-postReduceStress";
import CalorieDeficit from "./pages/blog-details/blog-postCalorieDeficit";
import PlantBased from "./pages/blog-details/blog-postPlantBased";
import SmartSnacking from "./pages/blog-details/blog-postSmartSnacking";
import BudgetEating from "./pages/blog-details/blog-postBudgetEating";
function App() {
  return (
            <MyProvider>
                    <Routes>
                        <Route path="/" element={<Home/>}></Route>
                        <Route path="about-us" element={<AboutUs />}></Route>
                        <Route path="services" element={<Services />}></Route>
                        <Route path="mens-health" element={<MensHealth/>}></Route>
                        <Route path="womens-health" element={<WomensHealth />}></Route>
                        <Route path="pediatric-health" element={<PediatricHealth/>}></Route>
                        <Route path="geriatric-health" element={<GeriatricHealth/>}></Route>
                        <Route path="contact-us" element={<ContactUs />}></Route>
                        <Route path="dr-isioma-okobah-foundations" element={<OkobahFoundation/>} />
                        <Route path="FAQ" element={<FAQ />} />
                        <Route path="Insurances" element={<Insurances/>} />
                        <Route path="office-policy" element={<OfficePolicy />} />
                        <Route path="PatientPortal" element={<PatientPortal />} />
                        <Route path="blog" element={<BlogList/>} />
                        <Route path="blog-post-urgent-care" element ={<BlogPostUrgentCare/>} />
                        <Route path="blog-post-importance-of-medical-insurance" element ={<BlogPostMedicalInsurance />} />
                        <Route path="blog-post-family-planning-birth-control" element ={<BlogPostBirthControl/>} />
                        <Route path="blog-post-annual-physicals" element ={<BlogPostAnnualPhysical/>} />
                        <Route path="blog-post-std-testing" element ={<BlogPostSTDtesting/>}/>
                        <Route path="blog-post-breast-cancer-awareness" element ={<BreastCancerAwareness/>}/>
                        <Route path="blog-post-what-is-a-heart-attack"  element ={<HeartAttack/>}/>
                        <Route path="blog-post-coronary-heart-disease"  element ={<CoronaryHeart/>}/>
                        <Route path="blog-post-reduce-stress"  element ={<ReduceStress/>}/>
                        <Route path="blog-post-calorie-deficit"  element ={<CalorieDeficit/>}/>
                        <Route path="blog-post-plant-based"  element ={<PlantBased/>}/>
                        <Route path="blog-post-smart-snacking"  element ={<SmartSnacking/>}/>
                        <Route path="blog-post-healthy-budget-eating"  element ={<BudgetEating/>}/>
                    </Routes>
            </MyProvider>
  );
}

export default App;
