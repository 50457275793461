import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import urgentcare from "../../assets/images/blog/lithonia-urgent-care.jpeg";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./blogdetails.css";



function BlogPostUrgentCare () {
        return (
            <div className="main">
                <NavBar />
                {/* <!-- Portfolio Details --> */}
                <div className="portfolio-details">
                    <div className="auto-container">

                            <div className="image-box-blog"><img src={urgentcare} alt="lithonia-urgent-care"/></div>

                        <div className="sec-title">
                            <h1>Urgent Care Visits vs Emergency Room Visits?</h1>
                        </div>
                        <div className="text">It’s Saturday afternoon and your two-month-old spikes a fever. You start to feel a terrible pain in your side, but your primary care physician can’t see you the same day. Most times, injuries and illnesses are completely unexpected. Finding urgent medical care becomes imperative, but the wait times at the emergency room are discouraging. </div>
                        <div className="text">A good thing to remember is that urgent care can handle a vast majority of cases and is a great step between the doctor’s office and the emergency room. Whether it’s the flu or a broken bone, knowing where to turn when you or your child needs help is information everyone  should have. At First Lithonia Medical Center we offer virtual care, walk-ins, same day appointments, and after hour appointments. Need to schedule your appointment after hours? Click here to <a href="sms:7703236458">text us.</a> </div>
                        <div className="text">So what exactly is the difference between urgent care and emergency departments?<br/>
                           <b>Urgent care:</b> Urgent care clinics provide on-demand healthcare for all non-life-threatening and non-chronic medical conditions, such as cold/flu, allergy treatment, sprains and fractures, minor vehicle accidents, and many more medical conditions. Walk-in medical centers also offer a variety of other healthcare services such as school and DOT physicals, lab tests, STD testing, COVID-19 testing, immunizations, and X-Rays. When you need immediate medical treatment for non-life-threatening conditions or having trouble getting an appointment, just “walk-in” and receive care.<br/>
                           <b>Emergency Department:</b> If you are experiencing a life-threatening medical emergency, head straight to the nearest hospital ER room or standalone center. These centers are open 24/7, doctors and specialists are always available, and they have the advanced equipment necessary for life-saving measures. NOTE: The cost of care to visit an ER for non-life-threatening conditions will be significantly higher than urgent care.</div>
                       <div className="text"> First Lithonia Medical Center is your go-to medical center for quick and convenient healthcare in the Dekalb County, Fulton County, and Rockdale County area. To schedule an appointment, visit our website: <a href= "tel:7703236458">call</a> or text us at 770 323 6458. </div>
                        <div className="project-post-pagination">
                            <div className="wrapper-box">

                                <div className="prev-post">
                                    <h5><a href="/"> Previous Post </a></h5>
                                </div>

                                <div className="next-post">
                                   <h5><a href="/blog-post-importance-of-medical-insurance">Next Topic </a></h5>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
}
export default BlogPostUrgentCare;