import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../component/navBar";
import AboutUsC from "../component/aboutUsC2";
import Experience from "../component/experience";
import Band from "../component/band";
import Testimonials from "../component/testimonials";
import ContactUsMain from "../component/contactUsMain";
import Footer from "../component/footer";
import Timeline from "../component/timeline";

function AboutUs() {
  return (
    <div className="main">
      <NavBar />

      <section
        className="main-banner inner-banner about-banner"
        style={{
            height: 250,
        }}
      >
        <Container>
          <Row>
            <Col>
              <div className="banner-content">
                <h1 className="h1-title">About FLMC</h1>
                <div className="breadcrumb-box">
                  <ul>
                    <li>
                      <a href="/" title="home">
                        Home
                      </a>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <AboutUsC />

    <Timeline/>

      <Experience />
      <Band/>
      <Testimonials />
      <ContactUsMain />
      <Footer />
    </div>
  );
}

export default AboutUs;
