import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./experience.css";
import chigni from "../assets/images/chigni.jpg"
import okobah from "../assets/images/okobah.jpg"
import godwins from "../assets/images/godwins.jpg"

function Experience() {
  return (

    <section className="main-experience">
      <Container className="provider-experience">
        <Row className="align-items-center">
          <Col lg={6} className="order-lg-1 order-2">
            <div className="experience-content">
              <title> Dr Okobah MD </title>
              <span className="sub-title">EXPERIENCE</span>
              <h2 className="h2-title">Dr. Isioma Okobah </h2>
              <p>
                Dr. Isioma Okobah is a board-certified physician in family medicine
                who has served the greater Atlanta community for almost two decades. She graduated
                from University of Benin College of Medical Sciences, Emory University’s Rollins
                School of Public Health and completed her residency in family medicine at Emory
                University Hospital. Dr. Okobah opened First Lithonia Medical Center in 2006, where
                she treats from newborns geriatrics. When she is not treating patients, she acts as
                the founder of her non-profit organization the Dr. Isioma Okobah Foundation. Optimal
                health coverage and preventive care for EVERYONE is something Dr. Okobah has
                actively been pursuing her entire career. She strongly believes in the future of
                medicine, so she frequently lends her expertise as an adjunct faculty at the
                Morehouse School of Medicine and Emory School of Medicine. Dr. Okobah is an advocate
                for routine immunizations.
              </p>
              <title>black doctor</title>
            </div>
            <div className="experience-skills">
              <div className="experience-skill-list">
                {/* Education and Training */}
                <div className="experience-skill-bar-box">
                  <h5 className="h3-title experience-skill-bar-title">
                    Education and Training
                  </h5>
                  <div className="experience-skill-list">
                    <ul>
                      <li><span> Doctor of Medicine, University of Benin College of Medical Sciences</span></li>
                      <li><span> Residency, Emory University Department of Family and Preventive Medicine</span>
                                        </li>
                                        <li><span> Masters in Public Health (MPH), Emory University’s Rollins School of Public
                                          Health</span>
                                        </li>
                    </ul>
                  </div>

                </div>
                {/* Certifications and Affiliations*/}
                <div className="experience-skill-bar-box">
                  <title> primary care doctor</title>
                  <h5 className="h3-title experience-skill-bar-title">
                    Certifications and Affiliations
                  </h5>
                  <div className="experience-skill-list">
                    <title> primary care doctor</title>
                    <ul>

                      <li><span>Board Certified, Family Medicine</span></li>
                      <li><span>Member, American Academy of Family Physicians</span></li>
                      <li><span>Member, Georgia Academy of Family Physicians</span></li>

                    </ul>
                  </div>
                </div>
                {/* Hospital Privileges*/}
                <div className="experience-skill-bar-box">
                  <title> primary care doctor</title>
                  <h5 className="h3-title experience-skill-bar-title">
                    Hospital Privileges
                  </h5>
                  <div className="experience-skill-list">
                    <title> primary care doctor</title>
                   <ul>
                     <li><span>Emory Decatur Hospital- DeKalb Medical</span></li>
                     <li><span>Emory Hillandale Hospital- DeKalb Medical</span></li>
                     <li><span>Emory University Hospital Midtown</span></li>
                   </ul>

                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6} className="order-lg-2 order-1">
            <div className="experience-img">
              <title> primary care doctor</title>
              <img
                src= {okobah}
                alt="Experience Image"
              />
            </div>
          </Col>
        </Row>

      </Container>


      <Container className="provider-experience">
        <Row className="align-items-center">
          <Col lg={6} className="order-lg-1 order-2">
            <div className="experience-content">
              <title> Mrs Bethel Godwins</title>
              <span className="sub-title">EXPERIENCE</span>
              <h2 className="h2-title">Mrs. Bethel Godwins </h2>
              <title> Godwins Nurse Practitioner</title>
              <p>
                Ms. Bethel Godwins, RN, MSN, FNP-C is a Provider at First Lithonia Medical Center. She
                provides compassionate, thorough, and effective patient care. Ms. Godwins began her clinical
                care journey in 2006 when she graduated from Kennesaw State University with her Bachelors of
                Nursing (BSN) Degree. After serving as a bedside nurse for over a decade, she obtained her
                Masters of Nursing from Walden University with a specialization in family care; Ms. Godwins
                graduated Summa Cum Laude.

                Ms. Godwins has excellent medical management skills, frequently managing patients with a
                myriad of illnesses and diseases. After 16 years of providing phenomenal patient care, she
                utilizes strong analytical and communication skills paired with a loving disposition to create an
                atmosphere of trust and security for her patients. Ms. Godwins loves to spend time with her
                husband and 4 daughters as well as her church community when she is not serving her patients.
                She has a strong conviction in her faith that “what the Lord cannot do does exist”
                Ms. Godwins is passionate about quality patient care for families from all backgrounds and
                hopes to care for your loved ones!
              </p>
            </div>
            <div className="experience-skills">
              <title> primary care doctor</title>
              <div className="experience-skill-list">
                <title> primary care doctor</title>
                {/* Education and Training */}
                <div className="experience-skill-bar-box">
                  <title> primary care doctor</title>
                  <h5 className="h3-title experience-skill-bar-title">
                    Education and Training
                  </h5>
                  <div className="experience-skill-list">
                    <title> primary care doctor</title>
                    <ul>
                      <li><span>Family Nurse Practitioner, Walden University</span> </li>
                      <li><span>Bachelors of Science in Nursing, Kennesaw State University </span></li>
                      <li><span>Associates Degree in Computer Programming, Chattahoochee Technical College</span></li>
                      <li><span>Bachelors of Social Science in Banking and Finance, University of Calabar, Nigeria</span></li>
                    </ul>
                  </div>

                </div>
                <title> primary care doctor</title>
                {/* Certifications and Affiliations*/}
                <div className="experience-skill-bar-box">
                  <title> primary care doctor</title>
                  <h5 className="h3-title experience-skill-bar-title">
                    Certifications and Affiliations
                  </h5>
                  <div className="experience-skill-list">
                    <title> primary care doctor</title>
                    <ul>
                      <li><span>Board Certified, American Academy Nurse Practitioners</span></li>
                      <li><span>Member, American Association of Nurse Practitioners</span></li>
                      <li><span>Member, American Nursing Association</span></li>
                      <li><span>Member, Georgia Nursing Association</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6} className="order-lg-2 order-1">
            <div className="experience-img">
              <title> primary care doctor</title>
              <img
                  src= {godwins}
                  alt="Bethel Godwins"
              />
            </div>
          </Col>
        </Row>

      </Container>

      <Container className="provider-experience">
        <Row className="align-items-center">
          <Col lg={6} className="order-lg-1 order-2">
            <div className="experience-content">
              <title> primary care doctor</title>
              <span className="sub-title">EXPERIENCE</span>
              <h2 className="h2-title">Mr. Adamou Chigni</h2>
              <p>
                Mr. Adamou Chigni, RN, MSN, FNP-C is a Provider at First Lithonia Medical Center originally from Cameroon.
                He has a particular interest in the health education and preventative care of pediatric, geriatric, and adult populations.
                Mr. Adam  began his clinical care journey in 2012 when he began his nursing program at Georgia Perimeter College.
                He graduated from Brenau University in 2017 where is began working at Emory University Hospital as a medical surgical registered nurse.
                He later pursued a master’s degree in nursing from Chamberlain University and graduated in 2021. He is the 2020 recipient of the Daisy Award, an award recognizing the most
                compassionate and clinically skilled providers. In Mr. Adam’s free time, he enjoys spending time with his family
                and traveling around the world. Mr Adam lives in Georgia with his wife and 3 children.
              </p>
            </div>
            <div className="experience-skills">
              <title>Chigni NP</title>
              <div className="experience-skill-list">
                <title>chigni nurse Practitioner</title>
                {/* Education and Training */}
                <div className="experience-skill-bar-box">
                  <h5 className="h3-title experience-skill-bar-title">
                    Education and Training
                  </h5>
                  <div className="experience-skill-list">
                    <title> primary care doctor</title>
                    <ul>
                      <li><span>Family Nurse Practitioner, Chamberlain University</span> </li>
                      <li><span>Bachelors of Science in Nursing, Brenau University</span> </li>
                      <li><span>Associates Degree in Nursing, Georgia Perimeter College</span></li>
                      <li><span>Bachelors of Science in Business Administration and Management, University of Yaounde Two</span></li>
                    </ul>
                  </div>

                </div>
                {/* Certifications and Affiliations*/}
                <div className="experience-skill-bar-box">
                  <title> primary care doctor</title>
                  <h5 className="h3-title experience-skill-bar-title">
                    Certifications and Affiliations
                  </h5>
                  <div className="experience-skill-list">
                    <title> primary care doctor</title>
                    <ul>
                      <li><span>Board Certified, American Academy Nurse Practitioners</span></li>
                      <li><span>Member, American Association of Nurse Practitioners</span></li>
                      <li><span>Member, American Nursing Association</span></li>
                      <li><span>Member, Georgia Nursing Association</span></li>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6} className="order-lg-2 order-1">
            <div className="experience-img">
              <title> primary care doctor</title>
              <img
                  src= {chigni}
                  alt="Adamou Chigni"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  );
}

export default Experience;
