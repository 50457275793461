import * as React from 'react';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import "./navBar.css";
import ScheduleIcon from '@mui/icons-material/Schedule';




const theme = createTheme({
    palette: {
        primary: {
            main: purple[500],
        },
        secondary: {
            main: '#811818FF',
        },
    },
});


function NavBarBottom() {
    return (
        <ThemeProvider theme={theme}>
        <>
            <div id="mybutton">
                <Button color="secondary" variant= "contained" className="bookings" href="https://healow.com/apps/practice/first-lithonia-medical-center-lithonia-ga-21141?v=1"> <ScheduleIcon/> <span style={{marginLeft:4}}>Book Appointment</span></Button>
            </div>
            </>
        </ThemeProvider>
    );


}
export default NavBarBottom;