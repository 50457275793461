import React from "react";

function OfficePolicy() {
  return <div>MEDICATION REFILL POLICY
    Please carefully read the following medication refill policy for First Lithonia Medical Center.  It is your responsibility to notify the office in a timely manner when refills are necessary.
    Approval of your refill may take up to three business days so please be courteous and do not wait to call. If you use a mail order pharmacy, please contact us fourteen (14) days before your medication is due to run out.
    Medication refills will only be addressed during regular office hours (Monday-Friday 8am-5pm). The urgent care staff will not return any phone calls regarding refills. Please notify your provider on the next business day if you find yourself out of medication after hours.
    Refills can only be authorized on medication prescribed by providers from our office. We will not refill medications prescribed by other providers.
    Some medications require prior authorization. Depending on your insurance this process may involve several steps by both your pharmacy and your provider. The providers and pharmacies are familiar with this process and will handle the prior authorization as quickly as possible. Only your pharmacy is notified of the approval status. Neither the pharmacy nor the provider can guaranty that your insurance company will approve the medication. Please check with your pharmacy or your insurance company for updates.
    It is important to keep your scheduled appointment to ensure that you receive timely refills. Repeated no shows or cancellations will result in a denial of refills. All prescriptions require a follow up appointment every 3 to 6 months (depending on the medication being prescribed for patient).
    If you have any questions regarding medications please discuss these during your appointment. If for any reason you feel your medication needs to be adjusted or changed please contact us immediately.
    New symptoms or events require a clinic appointment. Your provider will not diagnose or treat over the phone.</div>;
}

export default OfficePolicy;
