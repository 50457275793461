import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import plantbased from "../../assets/images/blog/chronicdiseases/plant-based-lithonia-doctor.jpg";
import "./blogdetails.css";
import "../blog.css"



function PlantBased () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <title>black nutrition doctor</title>
                <div className="auto-container">
                    <title>black nutrition doctor</title>

                    <div className="image-box-blog"><img src={plantbased} alt="lithonia-nutrition-doctor"/></div>
                    <title>black nutrition doctor</title>
                    <div className="sec-title">
                        <h1>Veganism and Vegetarianism: What’s The Difference?
                        </h1>
                    </div>
                    <div className="text"> According to the vegetarian society, a vegetarian is a person who abstains from eating meat,
                        poultry, fish, shellfish, game, or any by-products of animal slaughter. A vegetarian diet typically
                        contains fruits, vegetables, pulses, nuts, seeds, etc.
                        <title>black nutrition doctor</title>
                        <br/>
                        <br/>

                        There are several types of vegetarianism, but here are the most common.

                    <br/>
                    <br/>

                    <li> <b>Lacto vegetarians</b>: These are vegetarians who avoid animal flesh and eggs but do not
                        consume dairy products.</li>
                        <br/>
                       <li><b>Lacto-ovo vegetarians</b>: These are vegetarians who avoid all animal flesh but consume
                        eggs and dairy products.</li>
                <br/>
                        <li> <b>Ovo-vegetarians</b>: These are vegetarians who avoid all animal products except eggs.
            </li>
            <br/>
                        <li> <b>Vegans</b>: These are vegetarians who avoid all animals and animal-based products. They
                        are the strictest types of vegetarians.
        </li>
    <br/>
                    </div>

                    <div className="text">
                        <title>black nutrition doctor</title>
                        Vegans are considered the strictest type of vegetarianism. According to the vegan society,
                        veganism is defined as a way of life that tries to exclude all forms of animal cruelty and
                        exploitation. Therefore, a vegan diet excludes not only animal flesh but also eggs, dairy, and any
                        other ingredient that is extracted from animals. These ingredients include honey, whey, pepsin,
                        shellac, albumin, gelatin, etc. Even though vegetarians and vegans may choose to forgo animal
                        products for comparable reasons, for vegans, this decision frequently goes beyond diet. Vegans
                        avoid dairy and eggs, which many vegetarians have no difficulty consuming, out of a desire to
                        avoid all types of animal exploitation. In fact, many people believe veganism to be a strongly
                        pro-animal rights way of life. Due to this, many vegans refrain from buying garments made of
                        silk, wool, leather, or suede. In general, the amounts of most nutrients consumed by
                        vegetarians and vegans are comparable. Poorly planned diets, however, may lead to low
                        intakes of a variety of nutrients. Both vegetarian and vegan diets can be considered healthy
                        eating choices, although if a vegan diet is not properly planned, it could lead to nutrient
                        deficiencies.</div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-calorie-deficit"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-breast-cancer-awareness">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default PlantBased;