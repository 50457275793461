import React, { useContext } from "react";
import NavBar from "../component/navBar";
import NavBarBottom from "../component/navbarBottom";
import Banner from "../component/banner";
import ServicesC from "../component/servicesC";
import AboutUsC from "../component/aboutUsC";
import FAQ from "../component/faqC";
import Footer from "../component/footer";
import Pricing from "../component/pricing";
import "bootstrap/dist/css/bootstrap.min.css";
import { MyContext } from "../myContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Expertise from "../component/expertise";

function Home() {
    const { setIsSubmenuOpen } = useContext(MyContext);
    return (
        <div className="main">
            <NavBar />
            <section onMouseOver={() => setIsSubmenuOpen("")}>
                <Banner />
                <ServicesC />
                <AboutUsC />
                <FAQ/>
                <Expertise />
                <Pricing />
                <Footer />
            </section>
        <NavBarBottom/>
        </div>
    );
}

export default Home;