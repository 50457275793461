import React, { useState} from "react";
const MyContext = React.createContext(undefined);

const MyProvider = ({ children }) => {
  const [isSubmenuOpen, setIsSubmenuOpen] = useState("");

  return (
    <MyContext.Provider value={{ isSubmenuOpen, setIsSubmenuOpen }}>
      {children}
    </MyContext.Provider>
  );
};
export { MyContext, MyProvider };
