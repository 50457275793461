import React, { useState, useEffect, useContext } from "react";
import mainLogo from "../assets/logo/mainLogo.png";
import Button from '@mui/material/Button';
import { BsChevronDown } from "react-icons/bs";
import {Container, Col, Row, Nav} from "react-bootstrap";
import { MyContext } from "../myContext";
import { NavLink} from "react-router-dom";
import "./navBar.css";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const pageSubItems = [
  "Patient Resources",
  "Insurances",
  "Patient Portal",
  "FAQ",
  "Forms",
];
const aboutSubItems = ["About", "About Us", "Dr. Isioma Okobah Foundation"];

function NavBar() {
  const { isSubmenuOpen, setIsSubmenuOpen } = useContext(MyContext);
  const [sideMainNav, setSideMainNav] = useState(false);


  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setScroll(true);
      }
      if (window.scrollY < 50) {
        setScroll(false);
      }
    });
  }, []);

  const handleMouseOver = (e) => {
    const value = e.target.textContent;
    if (pageSubItems.includes(value) || aboutSubItems.includes(value)) {
      setIsSubmenuOpen(value);
    } else {
      setIsSubmenuOpen("");
    }
  };



  return (
      <>

        <header
            className={`${scroll ? "site-header sticky-header" : "site-header"}`}
            onMouseOver={handleMouseOver}
        >
          <Container>
            <Row className="d-flex align-items-center">
              <Col lg={12}>
                <div className="header-box">
                  <title>doctor near me</title>
                  <div className="site-logo">
                    <title>doctor near me</title>
                    <NavLink to="/">
                      <img src={mainLogo} className="img-logo" alt="first lithonia medical center" />
                    </NavLink>
                  </div>
                  <div className="header-menu">
                    <title>doctor near me</title>
                    <Nav
                        className={`${
                            sideMainNav
                                ? "main-navigation toggle-nav-menu"
                                : "main-navigation"
                        }`}
                    >
                      <button
                          className="toggle-btn"
                          onClick={() => setSideMainNav(!sideMainNav)}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                      </button>
                      <ul className="header-menu-items">
                        <li>
                          <a href= "/">Home</a>
                        </li>

                        <li
                            className={`${
                               aboutSubItems.includes(isSubmenuOpen)
                                    ? "sub-items show-about"
                                    : "sub-items"
                            }`}
                        >
                          <a href="#">
                            About
                            <BsChevronDown
                                style={{
                                  paddingLeft: "2px",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                            />
                          </a>
                          <ul className="sub-menu">
                            <li>
                              <a href="/about-us">About Us</a>
                            </li>
                            <li>
                              <a href="/dr-isioma-okobah-foundations">Dr. Isioma Okobah Foundation</a>
                            </li>

                          </ul>
                        </li>


                        <li>
                          <a href="/services">Services</a>
                        </li>

                        <li
                            className={`${
                                pageSubItems.includes(isSubmenuOpen)
                                    ? "sub-items show-pages"
                                    : "sub-items"
                            }`}
                        >
                          <a href="#">
                            Patient Resources
                            <BsChevronDown
                                style={{
                                  paddingLeft: "2px",
                                  fontSize: "12px",
                                  fontWeight: 500,
                                }}
                            />
                          </a>
                          <ul className="sub-menu">
                            <li>
                              <a href="/Insurances">Insurances</a>
                            </li>
                            <li>
                              <a href="https://mycw135.ecwcloud.com/portal18800/jsp/100mp/login_otp.jsp">Patient Portal</a>
                            </li>
                            <li>
                              <a href="/office-policy">Office Policy</a>
                            </li>
                            <li>
                              <a href="/FAQ">FAQ</a>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <a href="/blog">Blog</a>
                        </li>
                      <li>
                        <a href="/contact-us">Contact Us</a>
                      </li>
                        <Button variant="contained" color="error" style={{marginTop: -15, display: "flex-grow"}} href="https://healow.com/apps/practice/first-lithonia-medical-center-lithonia-ga-21141?v=1">
                          <FontAwesomeIcon icon={solid ( 'calendar-check') } style={{paddingRight: 5, maxHeight: 20}}/>
                          Book Appointment
                        </Button>
                    </ul>
                    </Nav>
                  </div>
                </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
}

export default NavBar;
