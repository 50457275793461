import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import stress from "../../assets/images/blog/chronicdiseases/avoid-stress-first-lithonia-medical.jpg";
import "./blogdetails.css";
import "../blog.css"



function ReduceStress () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog"><img src={stress} alt="lithonia-heart-doctor"/></div>

                    <div className="sec-title">
                        <h1>Reducing Stress For A Healthier Heart
                        </h1>
                    </div>
                    <div className="text"> Stress reveals itself frequently in life, whether it comes from regular deadlines, financial difficulties, or
                        relationship problems. Your body responds by speeding up your heartbeat and tightening your blood
                        vessels. Over time, these small blows can pile up and harm your health, especially your heart. Chronic
                        stress increases your risk for high blood pressure, heart disease, diabetes, obesity, and restless nights.
                        Even other bodily components, such as your intestines and lungs, are susceptible. While you can't
                        always control how much stress you experience in your life, you can try to change how you react to it.
                        Just like the automatic "fight or flight" response that kicks in when you’re scared, your muscles tense,
                        your heart rate increases, and your brain becomes more alert. Your body also has a built-in, healthy
                        relaxation response. When that’s triggered, the opposite happens: your breathing and heart rate slow
                        down, and your blood pressure decreases. Fortunately, you can learn to trigger that response with
                        practice. Choose an instructor or class to assist you in getting started, or try these strategies on your
                        own. If you struggle to get the hang of it at first, try not to become frustrated. Try a different strategy if
                        one doesn't work for you. You can learn a variety of techniques.

                        <br/>
                        <br/>

                        <b>1. DEEP BREATHING</b>: Deep breathing is inhaling slowly and deeply, letting your chest or stomach expand, and
                        then slowly exhaling. Many times, repeat. While it is calming and something you can do anytime,
                        anyplace, many individuals don&#39;t breathe deeply.
                        <br/>
                        <b>2. PROGRESSIVE MUSCLE RELAXATION</b>: After tensing your muscles for a little while, let them relax to feel the
                        results. Beginning with your toes, go up to your calves, then your face, on muscle group at a time.
                        <br/>
                        <b>3. MEDIATION</b>: One of the most researched methods for reducing stress is meditation, which entails
                        practicing present-focused awareness in order to stop focusing on the past or the future. Choose a place
                        that is as calm and undisturbed as possible. Make yourself comfortable by sitting, laying down, or
                        strolling. Concentrate your awareness on a single word or group of words, an item, or your breathing.
                        And be at peace with distractions, including thoughts, as they come and go.
                        <br/>
                        <b>4. GUIDED IMAGERY</b>: This involves a series of steps that include relaxing and visualizing the details of a calm,
                        peaceful setting, such as a garden.
                    </div>

                    <div className="text"> But achieving a state of calm doesn&#39;t necessarily involve remaining still. A professional counselor,
                        participating in a stress management program, attending an art class, or going for a brisk walk with
                        friends are some healthy methods to handle stress. For some people, being in nature may be quite
                        calming. De-stressing techniques like these can help strengthen your heart when combined with other
                        healthy behaviors. Consume more fruits, vegetables, and whole grains while consuming less salt, sugar,
                        and saturated fats, for instance. Increase your physical activity by dancing or attending meetings while
                        walking. Find workouts you genuinely enjoy doing and do them frequently. Spend enough time sleeping
                        soundly; also create a solid network of social support. Then reconsider some of the tried-and-true
                        methods you may be employing to cope with stress, such as excessive alcohol consumption, drug use,
                        smoking, or overeating. They may even make your stress and health worse.</div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-coronary-heart-disease"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-calorie-deficit">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ReduceStress ;