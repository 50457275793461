import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../component/navBar";
import Footer from "../component/footer";
import "../component/servicesC.css";
import "./Services.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import NavBarBottom from "../component/navbarBottom";

function Services() {
  return (
      <div className="main">
        <NavBar />

        <section
            className="main-banner inner-banner about-banner"
            style={{
              height: 250,
            }}
        >
          <Container>
            <Row>
              <Col>
                <div className="banner-content">
                  <h1 className="h1-title">Our Services</h1>
                  <div className="breadcrumb-box">
                    <ul>
                      <li>
                        <a href="/" title="home">
                          Home
                        </a>
                      </li>
                      <li>OUR SERVICES</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* services */}
        <section className="main-our-services">
          <Container>
            <div className="our-services-list">
              <Row>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <div className="our-service-text">
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid('heart-pulse')}/>
                      </div>
                      <title> check ups, wellness visits, annual physicals</title>
                      <h3 className="our-serv-icon-h3">Preventative Visits</h3>
                      <p className="our-serv-icon-p">
                        Preventative Visits at our clinic include screenings, vaccinations, counseling, and education to promote optimal health and prevent illness or disease. This should be done at least once a year.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title>black doctors</title>
                    <div className="our-service-text">
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid('brain')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Psychiatry</h3>
                      <p className="our-serv-icon-p">
                        Our clinic offers comprehensive psychiatry services, including evaluation, diagnosis, and treatment of mental health disorders such as anxiety, depression, bipolar disorder, and schizophrenia, using a range of evidence-based therapies, medications, and behavioral interventions.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title>black doctors</title>
                    <div className="our-service-text">
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid ('person-dots-from-line')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Allergy Testing</h3>
                      <p className="our-serv-icon-p">
                        Our clinic offers comprehensive allergy testing services using advanced diagnostic tools to identify and treat a wide range of allergic reactions.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title> black doctors</title>
                    <div className="our-service-text">
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid ( 'pills')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Medication Refills</h3>
                      <p className="our-serv-icon-p">
                        Our clinic provides efficient medication refill services to ensure timely and convenient access to prescribed medications for our patients.
                        <a href={'https://www.firstlithonia.com/office-policy'} style={{color:"#777777"}}> View our refill policy.</a>
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title> vaccines</title>
                    <div className="our-service-text">
                      <title>black doctors</title>
                      <div className="our-service-text-icon">
                        <title>shots</title>
                        <FontAwesomeIcon icon={solid ( 'syringe')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Immunizations</h3>
                      <p className="our-serv-icon-p">
                        Our clinic offers comprehensive immunization services, including vaccinations for children, adults, and travelers, as well as flu shots and immunization records.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title> black doctors</title>
                    <div className="our-service-text">
                      <title>labs</title>
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid ( 'vials')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Blood Draw</h3>
                      <p className="our-serv-icon-p">
                        Our clinic works with Quest Diagnostics to provides efficient and professional blood draw services, with experienced staff to ensure accuracy and comfort.
                      </p>
                      <title>black doctors</title>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title> black doctors </title>
                    <div className="our-service-text">
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid('head-side-cough')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Sick Visits</h3>
                      <p className="our-serv-icon-p">
                        Our clinic offers comprehensive sick visit services, including diagnosis, treatment, and management of acute illnesses such as flu, cold, and infections.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title>black doctors</title>
                    <div className="our-service-text">
                      <title>black doctors</title>
                      <div className="our-service-text-icon">
                        <title> virtual appointment</title>
                        <FontAwesomeIcon icon={solid('laptop-medical')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Virtual Care</h3>
                      <p className="our-serv-icon-p">
                        Our clinic offers comprehensive virtual care services, providing patients with convenient access to medical consultations, diagnosis, treatment, and follow-up appointments through secure video conferencing technology.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title> black cholesterol doctor</title>
                    <div className="our-service-text">
                      <title> black high blood pressure doctor</title>
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid ( 'file-medical')}/>
                      </div>
                       <title> black thyroid doctor</title>
                      <h3 className="our-serv-icon-h3">Chronic Diseases</h3>
                      <title> black diabetes doctor</title>
                      <p className="our-serv-icon-p">
                        Our clinic offers comprehensive chronic disease services, including diagnosis, treatment, and management of conditions such as diabetes, hypertension, and heart disease.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title> black pediatric doctor</title>
                    <div className="our-service-text">
                      <title> black kids doctor</title>
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid ( 'children')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Pediatric Care</h3>
                      <title> black sports physical doctor </title>
                      <p className="our-serv-icon-p">
                        Our clinic offers comprehensive healthcare services for children, including routine checkups, vaccinations, sick visits, and chronic illness management.
                      </p>
                      <title>black children doctor</title>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title>black covid tests</title>
                    <div className="our-service-text">
                      <title> black covid testing</title>
                      <div className="our-service-text-icon">
                        <FontAwesomeIcon icon={solid('vial-virus')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">COVID Testing</h3>
                      <p className="our-serv-icon-p">
                        Our clinic offers COVID testing services, such as PCR tests, to accurately detect the presence of the virus.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xl={3} sm={6}>
                  <div className="our-service-box">
                    <title>black depo doctor </title>
                    <div className="our-service-text">
                      <title>black birth control pills doctor</title>
                      <div className="our-service-text-icon">
                        <title>black std testing doctor</title>
                        <FontAwesomeIcon icon={solid('person-breastfeeding')}/>
                      </div>
                      <h3 className="our-serv-icon-h3">Family Planning</h3>
                      <p className="our-serv-icon-p">
                        Our clinic offers a range of family planning services, including counseling, contraception options, pregnancy testing, and STI screenings, in a confidential and supportive environment.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
          <NavBarBottom/>
        </section>
        {/* end of services */}


        <Footer />
      </div>
  );
}

export default Services;