import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import SimpleTestSlider from "./testimonialSlider";
import "./testimonials.css";

function Testimonials() {
  return (
    <>
      <div className="main-testimonial">
        <title>google reviews pcp</title>
        <Container>
          <Row className="d-flex align-items-center">
            <Col lg={6}>
              <div className="testimonial-content">
                <title>google reviews pcp</title>
                <span className="sub-title">TESTIMONIALS</span>
                <h2 className="h2-title">See What Our Patients Say </h2>
                <p>
                  We pride ourselves on the reviews we receive on many platforms. We strongly encourage all of our patients to leave honest reviews that reflect the experience they had at First Lithonia Medical Center. We are always so blown away by the extremely kind words we receive in the form of reviews, so we thank the people who go the extra mile to share their truthful opinions. Whether positive or negative, we are appreciative of the gesture. We have extracted a few of our Google Reviews to show.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="main-testimonial-slider">
                <title>google reviews pcp</title>
                <div className="testimonial-slider-box">
                  <title>google reviews pcp</title>
                  <SimpleTestSlider />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Testimonials;
