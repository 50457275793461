import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import mammogram from "../../assets/images/blog/womenshealth/mammograms-save-lives.png";
import "./blogdetails.css";
import "../blog.css"



function BreastCancerAwareness () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog"><img src={mammogram} alt="lithonia-std-testing-site"/></div>

                    <div className="sec-title">
                        <h1>Mammograms Save Lives <br/>
                            By: Kayla Lunceford
                        </h1>
                    </div>
                    <div className="text">As you know breast cancer month is coming up. We are in charge of keeping our bodies healthy and strong and maintaining a great lifestyle. Going to the doctors, getting mammograms, eating healthy for men and women. Staying on top of our health helps catch anything out of the ordinary and help put a stop to it before it spreads throughout the body. Although breast cancer screening cannot prevent breast cancer, it can help find breast cancer early when it is easier to treat. We want to keep you all cancer free and healthy the best way we can but we can’t do it without you! We would like to keep you here celebrating the wonderful days ahead with family and friends.
                        Let's say yes to consistency and proving that we have power over our bodies. Here are ways to promote breast cancer awareness.
                    </div>

                    <div className="text"> Let's say yes to consistency and proving that we have power over our bodies. Here are ways to promote breast cancer awareness. <br/>
                        <li>Spread the news: mammograms save lives.</li>
                        <li>Raise awareness about risk factors.</li>
                        <li>Share survivor stories.</li>
                        <li>Become an ambassador.</li>
                        <li>Take part in national, regional and local fundraising events.</li>
                        <li>Raise awareness of helpful resources, care services and support groups.</li>
                    </div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-std-testing"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-what-is-a-heart-attack">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default BreastCancerAwareness ;