import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import std from "../../assets/images/blog/sexualhealth/sexually-transmitted-disease-icon-first-lithonia-medical.jpg";
import "./blogdetails.css";
import "../blog.css"



function BlogPostSTDtesting () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog"><img src={std} alt="lithonia-std-testing-site"/></div>

                    <div className="sec-title">
                        <h2>"Safe sex is great sex, better wear a latex
                            'Cause you don't want that late text, that ‘I think I'm late’ text" - Dwayne Michael “Lil Wayne” Carter Jr
                        </h2>
                    </div>
                    <div className="text">Sexually transmitted diseases (STDs) have been known to mankind for most of recorded human history. Before the advent of modern medicine, people's lack of awareness and understanding of STDs contributed to the widespread transmission of the infections while few or no treatments were available to treat the conditions. </div>

                    <div className="text"> <b>1. Even virgins can contract an STD</b> <br/>
                        If you’re in any type of romantic relationship or have had sexual contact with someone else, you could have an STD. Many of the most common STDs can spread simply through skin-to-skin contact.
                        HPV, syphilis, and both kinds of herpes can spread with just skin-to-skin contact. Sores are the main concern with syphilis, but cancer-causing HPV varieties and the HPV varieties that cause genital warts are easily spread through skin contact. The HSV-1 strain of herpes, which causes cold sores, can be spread through normal family contact, which is why 3.7 billion people under age 50 have it.
                    </div>
                    <div className="text"><b>2. Be ready for more serious health issues down the road</b><br/>
                        HPV has a well-known connection to cervical cancer, but it can also cause cancer of the vulva, vagina, penis, or anus. Every year, about 20,000 women and 12,000 men are affected by cancers caused by HPV.
                        If you know you have HPV, you can be on the lookout for signs of these cancers and catch them early. If you don’t have the disease yet, you may still be eligible to get the vaccine.
                    </div>
                    <div className="text"> <b> 3. Be ready for a relationship discussion </b> <br/>
                        When starting a relationship with a new partner, it’s important to discuss sexual history. Your partner deserves to know if you have a serious STD or if they may contract a more common STD from you.
                        Talk to Dr. Elkhalili about getting tested for these leading common STDs:
                        <li>Chlamydia</li>
                       <li> Gonorrhea</li>
                        <li>HIV</li>
                        <li>Herpes</li>
                        <li>HPV</li>
                        <li>Syphilis</li>
                        <li>Trichomoniasis</li>
                        Even if you currently don’t have an STDs you should still practice safe sex.
                        <br/>
                        </div>
                    <div className="text"><b>4. Build trust in monogamous relationships</b><br/>
                        STDs have a reputation for being asymptomatic, lying dormant for years, or having such mild symptoms that the infected person never feels that they need to get tested or treated. In fact, three-quarters of all women and half of all men with chlamydia have no STD symptoms.
                        Imagine the anxiety you might experience if your partner starts to show symptoms years into your relationship. It’s easy to see how accusations of cheating could come up. Instead, get tested at the start of a relationship.
                    </div>

                    <div className="text"><b>5. Stay in charge of your sexual health</b><br/>
                        STD testing empowers you to take total control of your sex life. Routine testing and safe sex practices put you in the driver’s seat no matter what your romantic or sexual situation is.  You’ll be able to make informed choices about your sexual health.
                        We recommend STD testing for anyone who’s sexually active. Dr. Elkhalili and our team at   Apex Medical Professionals are ready to help. Call or request an appointment at our Fair Lawn office today or take advantage of our telemedicine services. Please contact the office at 201-797-2003 for more information.
                    </div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-family-planning-birth-control"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-breast-cancer-awareness">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default BlogPostSTDtesting ;