import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import smartsnacks from "../../assets/images/blog/nutrition/healthyeatingfirstlithoniamedicalcenter.jpg";
import "./blogdetails.css";
import "../blog.css"



function SmartSnacking () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <title>black nutrition doctor</title>
                <div className="auto-container">
                    <title>black nutrition doctor</title>
                    <div className="image-box-blog"><img src={smartsnacks} alt="black-lithonia-nutrition-doctor"/></div>
                    <title>black nutrition doctor</title>
                    <div className="sec-title">
                        <h1>Smart Snacking Tips For Adults and Teens
                        </h1>
                    </div>
                    <div className="text"> At First Lithonia Medical Center, we are committed to promoting healthy habits and lifestyles for our patients. One important aspect of a healthy lifestyle is proper nutrition, which includes making smart choices when it comes to snacking. In this blog post, we will discuss the benefits of smart snacking and provide some tips for choosing healthier snacks.
                        <title>black nutrition doctor</title>
                        <br/>
                        <br/>

                        Smart snacking has numerous benefits, including:

                    <br/>

                    <li> <b>Increased energy levels</b>: Snacking on healthy foods can help keep your energy levels up throughout the day, preventing the mid-afternoon slump.</li>
                        <br/>
                       <li><b>Improved concentration</b>: Eating healthy snacks can also improve concentration, which is especially important for students or those with demanding jobs.</li>
                <br/>
                        <li> <b>Weight management</b>: Choosing healthier snacks can help manage your weight by preventing overeating at meal times and keeping your metabolism working efficiently.
            </li>
            <br/>
                        <li> <b>Improved mood</b>: Snacking on healthy foods can also improve your mood and reduce stress levels.
        </li>
    <br/>
                    </div>

                    <div className="text">Here are some tips for making smarter snack choices:
                        <br/>

                    <li> <b>Look for whole foods</b>: Choose snacks that are made from whole, unprocessed foods such as fresh fruits and vegetables, nuts, and seeds.</li>
                    <br/>
                    <li><b>Avoid added sugars</b>: Avoid snacks that are high in added sugars, such as candy and sugary drinks.</li>
                    <br/>
                    <li> <b>Opt for protein</b>: Snacks that are high in protein, such as hard-boiled eggs, Greek yogurt, and nuts, can help keep you feeling full for longer.
                    </li>
                    <br/>
                    <li> <b>Read labels</b>: Be sure to read the labels on packaged snacks to avoid unhealthy ingredients like high fructose corn syrup, hydrogenated oils, and artificial flavors and colors.
                    </li>

                    </div>

                    <div className="text"> At First Lithonia Medical Center, we believe that making smart choices when it comes to snacking is an important part of a healthy lifestyle. By choosing whole foods, avoiding added sugars, and opting for protein-rich snacks, you can improve your energy levels, concentration, mood, and weight management. If you have any questions about healthy snacking or nutrition in general, please don't hesitate to contact us.</div>
                    <title>black nutrition doctor</title>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-plant-based"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-healthy-budget-eating">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default SmartSnacking;