import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import familyplanning from "../../assets/images/blog/lithonia-family-planning doctor.png";
import patch from "../../assets/images/blog/womenshealth/Patch-first-lithonia-medical-center.jpg";
import shot from "../../assets/images/blog/womenshealth/Shot-Injection-first-lithonia-medical-center.jpg";
import minipill from "../../assets/images/blog/womenshealth/The-Mini-Pill-first-lithonia-medical-center.jpg";
import pill from "../../assets/images/blog/womenshealth/the-pill-first-lithonia-medical-center.jpg";
import ring from "../../assets/images/blog/womenshealth/Vaginal-Contraceptive-Ring-first-lithonia-medical-center.jpg";
import "./blogdetails.css";



function BlogPostBirthControl () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog "><img src={familyplanning} alt="family planning services "/></div>

                    <div className="sec-title">
                        <h1>Female Birth Control Options</h1>
                    </div>
                    <div className="text">Many couples get together in hopes of starting a family, however there is also the question of “ When is the right time to have a baby?” There is no correct answer on the right time to have a baby, fortunately there are many ways to access birth control or contraception. Birth control allows individuals to control the size of their family, the spacing between children, or can help prevent pregnancy when you don’t want to have a baby.  </div>
                    <div className="text">How do you choose the right birth control where there are so many options? Our providers at First Lithonia Medical Center are experts in this field and will guide you towards what works best for you. There isn’t 1 method of birth control that’s right for everyone. Each birth control has pros and cons.</div>
                     <div className="text">Here are some questions you should consider when choosing a birth control method:<br/>
                    <ul className="birth-control-list">
                           Do you want to have children someday? How soon?
                            Do you have any health conditions?
                             How often do you have sex?
                            How many sex partners do you have?
                            Do you also need protection from HIV and other STDs?
                            How well does the birth control method work?
                           Are there any side effects?
                           Will you be able to use it correctly every time?
                    </ul>
                    </div>
                    <div className="text">A few birth control methods that our providers at First Lithonia Medical Center are able prescribe include: The Pill, mini-pill, the patch, vaginal ring, and the Depo shot. These treatments are fairly easy to start, with the first step being scheduling an appointment with one of our providers to discuss which option works for you. Using birth control methods promote responsible sexual health all across the board. </div>
                    <div className="text">Below is a list of birth control methods you can receive at First Lithonia Medical Center:
                        <br/>
                        **information provided from <a href="https://www.fda.gov/consumers/free-publications-women/birth-control-chart">FDA.gov</a>

                        <table>
                            <thead>
                            <tr>
                            <th scope="col"></th>
                            <th scope="col">Methods</th>
                            <th scope="col">Number of Pregnancies Expected</th>
                            <th>Use</th>
                            <th>Some Risks or Side Effects</th>
                           </tr>
                            </thead>
                            <tbdoy>
                                <tr>
                                    <td>
                                        <img alt="depo shots for women near me" className="img-responsive" src={shot} title="depo shot for women"/>
                                    </td>
                                    <td> Shot/Injection</td>
                                    <td>6</td>
                                    <td>Need a shot every 3 months.</td>
                                    <td>
                                        Loss of bone density
                                        <br/>
                                        Irregular bleeding/ Bleeding between periods
                                    <br/>
                                        Headaches
                                    <br/>
                                        Weight gain
                                    <br/>
                                        Nervousness
                                    <br/>
                                        Dizziness
                                    <br/>
                                        Abdominal discomfort
                                </td>
                            </tr>
                                <tr>
                                <td>
                                        <img src={pill} alt="Oral Contraceptives “The Pill” (Combined Pill)" title="Oral Contraceptives “The Pill” (Combined Pill)" className="img-responsive"/>

                                </td>
                                <td>Oral Contraceptives “The Pill” (Combined Pill)</td>
                                <td>9</td>
                                <td>Must swallow a pill every day.</td>
                                <td>
                                     Spotting/ bleeding between periods
                                    <br/>
                                     Nausea
                                    <br/>
                                    Breast tenderness
                                    <br/>
                                    Headache
                                </td>
                            </tr>
                            <tr>
                                <td>
                                        <img src={pill} alt="Oral Contraceptives “The Pill” (Extended/ Continuous Use Combined Pill)" title="Oral Contraceptives “The Pill” (Extended/ Continuous Use Combined Pill)" />
                                </td>
                                <td>Oral Contraceptives “The Pill” (Extended/ Continuous Use Combined Pill)</td>
                                <td>9</td>
                                <td>Must swallow a pill every day.</td>
                                <td>
                                    Spotting/ bleeding between periods
                                    <br/>
                                        Nausea
                                    <br/>
                                        Breast tenderness
                                    <br/>
                                        Headache
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img alt="Oral Contraceptives “The Mini Pill” (Progestin Only)" className="img-responsive" src={minipill} title="Oral Contraceptives “The Mini Pill” (Progestin Only)"/>
                                </td>
                                <td>Oral Contraceptives “The Mini Pill” (Progestin Only)</td>
                                <td>9</td>
                                <td>Must swallow a pill at the same time every day.</td>
                                <td>
                                    Spotting/ bleeding between periods
                                    <br/>
                                    Nausea
                                    <br/>
                                    Breast tenderness
                                    <br/>
                                    Headache
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img alt="Patch" class="img-responsive" src={patch} title="Patch"/>
                                </td>
                                <td>Patch</td>
                                <td>9</td>
                                <td>
                                    Put on a new patch each week for 3 weeks (21 total days).
                                    <br/>
                                    Don’t put on a patch during the fourth week.
                                </td>
                                <td>
                                    Spotting or bleeding between menstrual periods
                                    <br/>
                                   Nausea
                                       <br/>
                                   Stomach pain
                                       <br/>
                                       Breast tenderness
                                    <br/>
                                   Headache
                                       <br/>
                                  Skin irritation
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <img alt="Vaginal Contraceptive Ring" class="img-responsive" src={ring} title="Vaginal Contraceptive Ring"/>
                                </td>
                                <td>Vaginal Contraceptive Ring</td>
                                <td>9</td>
                                <td>Put the ring into the vagina yourself. Keep the ring in your vagina for 3 weeks and then take it out for one week.</td>
                                <td>
                                    Vaginal discharge, discomfort in the vagina, and mild irritation..
                                    <br/>
                                    Headache
                                    <br/>
                                    Mood changes
                                    <br/>
                                    Nausea
                                    <br/>
                                         Breast tenderness
                                </td>
                            </tr>
                            </tbdoy>
                    </table>
                    </div>
                    </div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-importance-of-medical-insurance "> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-family-planning-birth-control">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>

            <Footer />
        </div>
    )
}
export default BlogPostBirthControl ;