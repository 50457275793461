import React from "react";
import {Link} from "react-router-dom";
import annualphysical from "../assets/images/blog/annual-physical.jpg";
import mammogram from "../assets/images/blog/womenshealth/mammograms-save-lives.png";
import familyplanning from "../assets/images/blog/lithonia-family-planning doctor.png"
import healthinsurance from "../assets/images/blog/health-insurance.jpg";
import urgentcare from "../assets/images/blog/lithonia-urgent-care.jpeg"
import std from "../assets/images/blog/sexualhealth/std-testing-first-lithonia-medical-center.jpg";
import heartattack from "../assets/images/blog/chronicdiseases/first-lithonia-medical-center-national-heart-month.jpg";
import checkupheart from "../assets/images/blog/chronicdiseases/first-lithonia-check-up-heart.jpg";
import stress from "../assets/images/blog/chronicdiseases/avoid-stress-first-lithonia-medical.jpg";
import calorie from "../assets/images/blog/chronicdiseases/calorie-deficit-first-lithonia-medical-center.jpg";
import plantbased from "../assets/images/blog/chronicdiseases/plant-based-lithonia-doctor.jpg";
import smartsnacks from "../assets/images/blog/nutrition/healthyeatingfirstlithoniamedicalcenter.jpg";
import budget from "../assets/images/blog/nutrition/Eating Healthy on a Budget First Lithonia Medical Center.png";
import NavBar from "../component/navBar";
import Footer from "../component/footer";

import "./blog.css";


function BlogList() {
  return (

      <div className="main">
        <NavBar />
    {/* <!-- News Section --> */}
    <section class="news-section">
      <div class="auto-container">
        <div class="row">
          {/* <!-- News Block One --> */}
          <div class="news-block-one col-lg-4">
            <div class="inner-box">
              <div class="image"><Link to={'/blog-post-annual-physicals'}><img
                  src={annualphysical} alt="annual physicals near me"/></Link></div>
              <div class="lower-content">
                <ul class="post-meta">
                  <li><a href='/#'>12th September
                    2022</a></li>
                </ul>
                <h3><Link to={'/blog-post-annual-physicals'}>The Annual Visit That's All About You!</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-annual-physicals" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div class="news-block-one col-lg-4">
            <div class="inner-box">
              <div class="image"><a href='https://www.firstlithonia.com/blog-post-urgent-care'><img
                  src={urgentcare} alt="urgent care near me"/></a></div>
              <div class="lower-content">
                <ul class="post-meta">
                  <li><a href='/#'>16th September 2022</a></li>
                </ul>
                <h3><a href='https://www.firstlithonia.com/blog-post-urgent-care'>The Difference Between Urgent Care And The ER </a></h3>
                <a href="https://www.firstlithonia.com/blog-post-urgent-care" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div class="news-block-one col-lg-4">
            <div class="inner-box">
              <div class="image"><a href='/blog-post-importance-of-medical-insurance'><img
                  src={healthinsurance} alt="doctors that accept your insurance"/></a></div>
              <div class="lower-content">
                <ul className="post-meta">
                  <li><a href='/#'>19th September 2022</a></li>
                </ul>
                <h3><a href='https://www.firstlithonia.com/blog-post-importance-of-medical-insurance'>The Importance of Medical Insurance</a></h3>

                <a href="https://www.firstlithonia.com/blog-post-importance-of-medical-insurance" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><a href='/blog-post-family-planning-birth-control'><img
                  src={familyplanning} alt="family planning doctor near me"/></a></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><a href='/#'>20th September 2022</a></li>
                </ul>
                <h3><a href='/blog-post-family-planning-birth-control'>Female Birth Control Options</a></h3>

                <a href="/blog-post-family-planning-birth-control"
                   className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>


          {/* <!-- News Block One --> */}
          <div class="news-block-one col-lg-4">
            <div class="inner-box">
              <div class="image"><Link to={'/blog-post-std-testing'}><img
                  src={std} alt="lithonia-std-testing"/></Link></div>
              <div class="lower-content">
                <ul class="post-meta">
                  <li><Link to={'/blog-post-std-testing'}>26th September
                    2022</Link></li>
                </ul>
                <h3><Link to={'/blog-post-std-testing'}>Safe Sex Is Great Sex</Link></h3>

                <a href="https://www.firstlithonia.com/blog-post-std-testing" className="btn-blog">
                  Read More
                </a>

              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div class="news-block-one col-lg-4">
            <div class="inner-box">
              <div class="image"><Link to={'/blog-post-breast-cancer-awareness'}><img
                  src={mammogram} alt=""/></Link></div>
              <div class="lower-content">
                <ul class="post-meta">
                  <li><Link to={'/blog-post-breast-cancer-awareness'}>28th September
                    2022</Link></li>
                </ul>
                <h3><Link to={'/blog-post-breast-cancer-awareness'}>Mammograms Save Lives!</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-breast-cancer-awareness" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><Link to={'/blog-post-what-is-a-heart-attack'}><img
                  src={heartattack} alt=""/></Link></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><Link to={'/blog-post-what-is-a-heart-attack'}>1st February
                    2023</Link></li>
                </ul>
                <h3><Link to={'/blog-post-what-is-a-heart-attack'}>Stop Heart Attacks!</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-what-is-a-heart-attack" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><Link to={'/blog-post-coronary-heart-disease'}><img
                  src={checkupheart} alt=""/></Link></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><Link to={'/blog-post-coronary-heart-disease'}>2nd February 2023 </Link></li>
                </ul>
                <h3><Link to={'/blog-post-coronary-heart-disease'}>What Is Coronary Heart Disease?</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-coronary-heart-disease" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><Link to={'/blog-post-reduce-stress'}><img
                  src={stress} alt=""/></Link></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><Link to={'/blog-post-reduce-stress'}>20th February 2023</Link></li>
                </ul>
                <h3><Link to={'/blog-post-reduce-stress'}>Reducing Stress For A Healthier Heart</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-reduce-stress" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><Link to={'/blog-post-calorie-deficit'}><img
                  src={calorie} alt=""/></Link></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><Link to={'/blog-post-calorie-deficit'}>7th March 2023</Link></li>
                </ul>
                <h3><Link to={'/blog-post-calorie-deficit'}>How to Create a Calorie Deficit For Weight Loss</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-calorie-deficit" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>
          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><Link to={'/blog-post-plant-based'}><img
                  src={plantbased} alt=""/></Link></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><Link to={'/blog-post-plant-based'}>13th March 2023</Link></li>
                </ul>
                <h3><Link to={'/blog-post-plant-based'}>Veganism and Vegetarianism: What’s The Difference?</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-plant-based" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>

          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><Link to={'/blog-post-smart-snacking'}><img
                  src={smartsnacks} alt=""/></Link></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><Link to={'/blog-post-smart-snacking'}>18th March 2023</Link></li>
                </ul>
                <h3><Link to={'/blog-post-smart-snacking'}>Smart Snacking Tips For Adults and Teens</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-smart-snacking" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>


          {/* <!-- News Block One --> */}
          <div className="news-block-one col-lg-4">
            <div className="inner-box">
              <div className="image"><Link to={'/blog-post-healthy-budget-eating'}><img
                  src={budget} alt=""/></Link></div>
              <div className="lower-content">
                <ul className="post-meta">
                  <li><Link to={'/blog-post-healthy-budget-eating'}>21st March 2023</Link></li>
                </ul>
                <h3><Link to={'/blog-post-healthy-budget-eating'}>Eating Healthy On A Budget</Link></h3>
                <a href="https://www.firstlithonia.com/blog-post-healthy-budget-eating" className="btn-blog">
                  Read More
                </a>
              </div>
            </div>
          </div>











        </div>
      </div>
    </section>
        <Footer/>
</div>

  );
}

export default BlogList;
