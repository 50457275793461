import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaFax } from "react-icons/fa";
import mainLogo from "../assets/logo/mainLogo.png";
import "./footer.css";

function Footer() {
  return (
    <>

      <div style={{ marginTop: "2rem" }}></div>
      <footer className="site-footer">
        <Container>
          <Row className="d-flex">
            <Col lg={3}>
              <div className="footer-info">
                <title>Caring medical center</title>
                <div className="footer-logo">
                  <title>Caring medical center</title>
                  <a href="#" title="Shiva">
                    <img src={mainLogo} alt="logo" />
                  </a>
                </div>
                <p>
                  Family Lithonia Medical Center takes care of patients ranging from newborns to the elderly. We create a caring relationship with our patients and their families. We listen to our patients, and ensure we help them make the right health
                  care decisions.
                </p>
              </div>
            </Col>

            <Col className="contact-us" lg={3}>
              <div className="footer-contact">
                <title>Caring medical center</title>
                <h3 className="h3-title footer-title">Contact Us</h3>
                <div className="footer-contact-box">
                  <title>Caring medical center</title>
                  <div className="footer-contact-link">
                    <title>Caring medical center</title>
                    <span className="icon">
                      <FaMapMarkerAlt />
                    </span>
                    <a href="https://www.google.com/maps/dir//first+lithonia+medical+center/@33.7167376,-84.1743833,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88f5ac680dd50927:0x11d26d1ef90d849!2m2!1d-84.166525!2d33.7153554">
                      2505 Panola Road Lithonia, Georgia 30058
                    </a>
                  </div>
                </div>

                <div className="footer-contact-box">
                  <title>Caring medical center</title>
                  <div className="footer-contact-link">
                    <title>Caring medical center</title>
                    <span className="icon">
                      <FaPhoneAlt />
                    </span>
                    <a href="tel:7703236458"> 770-323-6458</a>
                  </div>
                </div>

                <div className="footer-contact-box">
                  <title>Caring medical center</title>
                  <div className="footer-contact-link">
                    <title>Caring medical center</title>
                    <span className="icon">
                      <FaFax />
                    </span>
                    <a href="#">770-323-6462</a>
                  </div>
                </div>

                <div className="footer-contact-box">
                  <title>Caring medical center</title>
                  <div className="footer-contact-link">
                    <title>Caring medical center</title>
                    <span className="icon">
                      <FaEnvelope />
                    </span>
                    <a href="mailto:firstlithonia@gmail.com">firstlithonia@gmail.com</a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="our-links">
                <title>Caring medical center</title>
                <h3 className="h3-title footer-title">Our Links</h3>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/about-us">About Us</a></li>
                  <li><a href="/services">Services</a></li>
                  <li><a href="/okobah-foundation">Foundation</a></li>
                  <li><a href="/blog">Blog</a></li>
                  <li><a href="/contact-us">Contact Us</a></li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
      <section className="footer-last">
        <Container>
          <Row className="d-flex align-items-center">
            <Col lg={6}>
              <div className="copy-right">
                <title>Caring medical center</title>
                <p>
                  Copyright &copy; 2023
                  <a href="#"> First Lithonia Medical Center</a>.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      </>
  );
}

export default Footer;
