import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../component/navBar";
import NavBarBottom from "../../component/navbarBottom";
import Footer from "../../component/footer";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import pediatrichealth from "../../assets/images/pediatric-health-services2.jpg";



function PediatricHealth() {
    return (
        <div className="main">
            <NavBar />

            <section
                className="main-banner inner-banner about-banner"
                style={{
                    height: 100,
                }}
            >
                <Container>
                    <Row>
                        <Col>
                            <div className="banner-content">
                                <title>pediatric physician</title>
                                <h1 className="h1-title">Our Services</h1>
                                <div className="breadcrumb-box">
                                    <title>pediatric physician</title>
                                    <ul>
                                        <li>
                                            <a href="/" title="home">
                                                Home
                                            </a>
                                        </li>
                                        <li>PEDIATRIC HEALTH</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <!-- Sidebar Page Container --> */}
            <div class="sidebar-page-container">
                <title>pediatric physician</title>
                <div class="auto-container">
                    <title>pediatric physician</title>
                    <div class="row">
                        <title>pediatric physician</title>
                        <div class="col-lg-4">
                            <title>pediatric physician</title>
                            <aside class="sidebar">
                                {/* <!-- Category Widget --> */}
                                <div class="sidebar-widget-two categories-widget-two">
                                    <title>pediatric physician</title>
                                    <div class="widget-content">
                                        <title>pediatric physician</title>
                                        <h8 className="heading-8"> Our Services Include:</h8>
                                        <ul>
                                            <li> <ArrowRightIcon/>Well child checks</li>
                                            <li><ArrowRightIcon/>Immunizations</li>
                                            <li><ArrowRightIcon/>Sports physicals</li>
                                            <li><ArrowRightIcon/>Lab tests</li>
                                            <li><ArrowRightIcon/>School and college physicals</li>
                                            <li><ArrowRightIcon/>Sports injury evaluation</li>
                                            <li><ArrowRightIcon/>Depression Screening</li>
                                        </ul>
                                    </div>
                                </div>
                            </aside>
                        </div>

                        <div className="col-lg-8">
                            <title>pediatric physician</title>
                            <div className="services-details pl-lg-5">
                                <title>pediatric physician</title>
                                <div className="image-box"><img
                                    src={pediatrichealth}
                                    alt="kid's health"
                                    style={{height:400, marginTop:0}}/>
                                </div>
                                <div className="content">
                                    <title>pediatric physician</title>
                                    <div className="sec-title">
                                        <title>pediatric physician</title>
                                        <div className="sub-title">Pediatric Health</div>
                                        <h2>Comprehensive Care For<br/> Infants, Children, and Adolescents.</h2>
                                    </div>
                                    <div className="text">Your child's health includes physical, mental and social well-being. Most parents know the basics of keeping children healthy, like offering them healthy foods, making sure they get enough sleep and exercise and insuring their safety. It is also important for children to get regular checkups with their health care provider. These visits are a chance to check your child's development. They are also a good time to catch or prevent problems.</div>
                                    <title>pediatric physician</title>
                                    <blockquote>
                                        <div className="text" style={{fontSize:20}}><b>"</b>The first years of a child's life are too important for a child's future - their development, earnings, behavior, and health - for anyone to ignore.<b>"</b>
                                        </div>
                                        <h4> —Shakira</h4>
                                    </blockquote>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NavBarBottom/>
            <Footer />
        </div>

    );
}


export default PediatricHealth;