import React, { Component } from "react";
import Slider from "react-slick";

export default class SimpleTestSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      autoplaySpeed: 10000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      initialSlide: 0,
    };
    return (
      <div>
        <title>google reviews pcp</title>
        <Slider {...settings}>
          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              We definitely recommend this wonderful doctors office! Dr. Okobah has taken great care of my beautiful daughter throughout the years. She has been a patient since she was a baby and now she is 15 years old!! The love and patience that the office has shown her is amazing!!! Thank you First Lithonia Medical for always taking care of my precious daughter!!
            </p>
            <h3 className="h3-title">Lakiaisthe Queen</h3>

          </div>

          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              I have been a patient at First Lithonia Medical for 10 years, and I love, love, love this office! Dr. Okobah is amazing, and genuinely cares for her patients and their health. She is extremely patient, and takes time to hear your concerns and actually address them all. Her staff is equally awesome and caring. I actually look forward to coming in for my appointments, and would recommend this office to anyone looking for a new primary care provider.
            </p>
            <h3 className="h3-title">Kendra Banks</h3>

          </div>

          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              Pleased! So far, I've found what I'm looking for at First Lithonia Medical Center. Dr. Okobah is my new physician. She is personable, caring and professional.  Her administrative staff and medical team are friendly and efficient too. I especially applaud Ayanna who was patient and understanding about my needle phobia!  I have something nice to say about all of them but this would become a long review! I guess I'll post for each visit! 🙂 This is a convenient location by car or public transportation.  A clean waiting area and good protocols for social distancing and mask wearing. Thanks!
            </p>
            <h3 className="h3-title">Forever Ikenna</h3>

          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              After several appts with doctors in the area, I decided to stay with Doctor Isioma Okobah. I found it hard to find good, quality care in my area. Her office is impeccably clean, the staff is warm and friendly, not nice nasty like some in the area. My care was thorough and timely. No more waiting for 45 mins to an hour to be seen. Thanks Lithonia Medical Center!
            </p>
            <h3 className="h3-title">Narudo UziMonkey</h3>

          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              Dr Okobah has been my doctor since middle school and I’m 22 now. I trust her 100% with any concerns I may have. I miss the old staff because they’ve been there since before I started visiting and I’ve never had a problem with the receptionist. Every time I would go she’s joke around with me. I was sad my last visit to see so many new face I’ve gotten comfortable over the years with the entire office staff. Also I wish that I could see the doc (Dr.Okobah) because there’s a new doc there now in her absence. I found myself being a little more private with the new Doc. But overall I recommend this place 100%
            </p>
            <h3 className="h3-title">Towana Thomas</h3>

          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              I have been a patient at First Lithonia Medical Center for over two years. I have always been impressed with their professionalism, the NP (Mrs. Godwins) and the staff is well trained and very knowledgeable. But I've remained a patient there because of the personal attention I receive.
              I am also very impressed with their covid protocol. They make every effort to keep the patients and staff safe.
            </p>
            <h3 className="h3-title">AG Harris</h3>

          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              My son Zayid and I love Dr Okobah and her team. Lateria and Kyla always go the extra mile to help accommodate our needs in regards to screenings and checkups, especially physicals and examinations thethat are required for my son in regards to school and or other activities. The energy here is so warm and loving and they always take the time to make sure that we have got solid communication in regards to business. I really appreciate you guys thank you so much for everything always.
            </p>
            <h3 className="h3-title">Jade Mahmud</h3>

          </div>


          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              My mom referred me here and I referred my daughter.We have been in this facility for about 10 years and are very pleased with the staff always nice helpful and my doctor is wonderful very caring.
            </p>
            <h3 className="h3-title">Janell Shannon</h3>

          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              I love Dr. Okabah, she is great with my kids and knows they're names, which means they are not just patients to her. My oldest, who is now 19 years old has been going to Dr. Okabah since she was 6 years old and my youngest, who is 9 years old, since she was a newborn. My older sister and niece go here as well. I've never had any complaints, the staff are very friendly and professional. My questions and concerns are always answered before leaving. Dr. Okabah and her staff really know how to deal with kids very well. If I could give more than 5 stars, I would. I love First Lithonia Medical Center.
            </p>
            <h3 className="h3-title">Positive Gem</h3>

          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              Great doctor's office perfect for the whole family been coming here for a long time since at least 09 the staff is always friendly they always greet you with a smile and always respectful and caring I would recommend this place to anyone looking for a great family doctor
            </p>
            <h3 className="h3-title">Thaddeus Lencrerot</h3>

          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              This is the best primary care physician (PCP) you can have. Everyone here is very professional and puts the patient's well being as their top priority. Dr. Okabah is the only prinary care physician you should see !
            </p>
            <h3 className="h3-title">Rachel Elad</h3>
          </div>



          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              I rarely write reviews and only when well earned!
              Dr.Okobah has been my primary care doctor since 1992, 24 years; since the early years of Emory Clinic to now at First Lithonia Medical Center.  I have always trusted and have been satisfied with Dr. Okobah experience and care.  Through yard insect bite infections, to nasal conditions, to much more complicated medical conditions through the years;  Dr. Okobah has always been there for me.
              You can't go wrong!
              You're on the right road with Dr. Okobah and staff!
            </p>
            <h3 className="h3-title">G. Francois</h3>
          </div>


          <div className="review-box">
            <title>google reviews pcp</title>
            <p>
              Dr.Okobah has been my doctor for Ten years Relocated to Lithonia found a good doctor thank you for all you've done.
            </p>
            <h3 className="h3-title">Lavern Johnson</h3>
          </div>
        </Slider>
      </div>
    );
  }
}
