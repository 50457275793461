import React from "react";
import Slider from "react-slick";
import { Col, Container, Row } from "react-bootstrap";
import insurance from "../assets/images/health-medical-insurance-near-you.jpg"
import "./pricing.css";

function Pricing() {
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <section className="main-pricing">
      <Container>
        <Row>
          <Col>
            <div className="pricing-title">
              <title>humana pcp</title>
              <span className="sub-title">Your Insurance Guide</span>
              <h2 className="h2-title">Accepted Insurance List</h2>
              <title>wellcare pcp</title>
            </div>
          </Col>
        </Row>

        <Slider {...settings}>
          {/* slide 1 */}
          <div>
            <title>medicare pcp</title>
            <div className="pricing-box">
              <div className="pricing-box-text">
                <h3 className="h3-title">Medicare</h3>
                <div className="pricing-img">
                  <img
                    src={insurance}
                    alt="medicare insurance"
                  />
                </div>
                <div className="pricing-text-list">
                  <title>pcp near me</title>
                  <ul>
                    <li>Aetna Medicare Advantage</li>
                    <li>Allwell Medicare Advantage</li>
                    <li>Anthem Medicare Advantage</li>
                    <li>Cigna Health Springs</li>
                    <li>Clover Health</li>
                    <li>Humana Medicare Advantage</li>
                    <li>Medicare Part B of Georgia</li>
                    <li>Sonder Health Plans</li>
                    <li>United Healthcare Medicare Advantage</li>
                    <li>Wellcare Medicare Advantage</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* slide 2 */}
          <div>
            <div className="pricing-box">
              <title>oscar pcp</title>
              <div className="pricing-box-text">
                <title>ambetter pcp</title>
                <h3 className="h3-title">ObamaCare</h3>
                <div className="pricing-img">
                  <title>pcp near me</title>
                  <img
                      src={insurance}
                      alt="obama care insurance"
                  />
                </div>
                <div className="pricing-text-list">
                  <title>friday health plan pcp</title>
                  <ul>
                    <li>Ambetter (Except Georgia Select Plans)</li>
                    <li>Friday Health Plan</li>
                    <li>Oscar Health Plan</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* slide 3 */}
          <div>
            <title>pcp near me</title>
            <div className="pricing-box">
              <title>peach state pcp</title>
              <div className="pricing-box-text">
                <title>amerigroup pcp</title>
                <h3 className="h3-title">Medicaid</h3>
                <title>medicaid pcp</title>
                <div className="pricing-img">
                  <title>pcp near me</title>
                  <img
                      src={insurance}
                      alt="medicaid insurance"
                  />
                </div>
                <div className="pricing-text-list">
                  <title>caresource pcp</title>
                  <ul>
                    <li>Amerigroup</li>
                    <li>Caresource</li>
                    <li>Department of Community Health Medicaid</li>
                    <li>Peachstate</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* slide 4 */}
          <div>
            <div className="pricing-box">
              <title>tricare pcp</title>
              <div className="pricing-box-text">
                <title>all savers pcp</title>
                <h3 className="h3-title">Commercial Insurance</h3>
                <div className="pricing-img">
                  <title>umr pcp</title>
                  <img
                      src={insurance}
                      alt="commercial"
                  />
                </div>
                <div className="pricing-text-list" style={{columns:2,  position: "absolute" }}>
                  <title>united healthcare  pcp</title>
                  <ul className="commercial">
                    <li>Aetna</li> <li>All Savers</li>
                    <li>Allied benefits</li> <li>Allied National</li>
                    <li>Auxiant</li> <li>Benefit Administrative Services</li>
                    <li>Bind</li>  <li>Blue Cross Anthem</li>
                    <li>ChampVA</li>  <li>Cigna (Except Local Plus Plans)</li>
                    <li>EBMS</li> <li>Emblem Health</li>
                    <li>First Health Network</li> <li>Fringe Benefit Group</li>
                    <li>GEHA</li> <li>Golden Rule</li>
                    <li>Humana</li> <li>Southern Guaranty Insurance Group</li>
                    <li>Tricare</li> <li>UMR</li>
                    <li>United Health One</li> <li>United Healthcare</li>
                    <li>Web TPA</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </Container>
    </section>
  );
}

export default Pricing;
