import React from "react";
import "./faqC.css";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function FAQ() {
  return (
      <section id="main-faq">
        <div className="sub-faq">
          <title> lithonia primary care doctor</title>
              <div className="faq-content-left">
                <title> lithonia primary care doctor</title>
                <h4 className="h4-title">Frequently Asked Questions</h4>
                <p>

                  <b> For more frequently asked questions, </b>
                  <a href={"https://www.firstlithonia.com/faq"} style={{textDecoration: 'none', color: '#9b111e', fontSize:18.5}}>
                  <b>click here</b>
                  </a>
                </p>
                <span>

                </span>
              </div>

                <div className="faq-content-right">
                  <title> lithonia primary care doctor</title>
                    <div className="box">
                      <title> lithonia primary care doctor</title>
                      <div className="title-box">
                        <title> lithonia primary care doctor</title>
                        <h1>Are you currently accepting new patients?</h1>
                        <FontAwesomeIcon  className="icon" icon={solid("chevron-down")} style={{fontWeight:900, fontSize: 12}}/>
                      </div>
                      <p style={{color: 'whitesmoke'}}>
                      Yes, we are currently accepting new patients. Same-day appointments, walk-ins, and Saturday
                        appointments are available for new patients. You can call the office during normal business hours or simply walk-in.
                      </p>
                    </div>
                  <div className="box">
                    <title> lithonia primary care doctor</title>
                    <div className="title-box">
                      <title> lithonia primary care doctor</title>
                      <h1>I don't have insurance, how much will my visit cost?</h1>
                      <FontAwesomeIcon  className="icon" icon={solid("chevron-down")} style={{fontWeight:900, fontSize: 12}}/>
                    </div>
                    <p style={{color: 'whitesmoke'}}>
                      For new patients the cost of a visit is $200 for new patients and $155 for established patients. Virtual visits are a flat rate of $100 for new and established patients.
                      **Note: The cost of these visits do not include lab work. Separate pricing applies.
                    </p>
                  </div>
                  <div className="box">
                    <title> lithonia primary care doctor</title>
                    <div className="title-box">
                      <title> lithonia primary care doctor</title>
                      <h1>How often should I have a comprehensive physical?</h1>
                      <FontAwesomeIcon  className="icon" icon={solid("chevron-down")} style={{fontWeight:900, fontSize: 12}}/>
                    </div>
                    <p style={{color: 'whitesmoke'}}>
                    The providers at First Lithonia Medical Center highly encourage our patients to complete a comprehensive physical once a year.
                      Most insurances offer a FREE annual physical. This is a health benefit that is 100% covered, so why not take advantage of it?
                      </p>
                  </div>
                </div>
        </div>
      </section>
  );
}

export default FAQ;
