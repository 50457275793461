import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import budgeteating from "../../assets/images/blog/nutrition/First Lithonia Guide to Eating Healthy on a Budget.png";
import "./blogdetails.css";
import "../blog.css"



function BudgetEating () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <title>black nutrition doctor</title>
                <div className="auto-container">
                    <title>black nutrition doctor</title>
                    <div className="image-box-blog"><img src={budgeteating} alt="black-lithonia-nutrition-doctor"/></div>
                    <title>black nutrition doctor</title>
                    <div className="sec-title">
                        <h1>Eating Healthy On A Budget
                        </h1>
                    </div>
                    <div className="text"> Are you trying to eat healthy but find it difficult because of the cost? You are not alone. Many people think that healthy eating is expensive, but it doesn't have to be. At First Lithonia Medical Center, we understand the importance of maintaining a healthy diet while sticking to a budget. Here are some tips and tricks to help you eat healthy on a budget.
                        <br/>
                        <br/>


                    <li> <b>Plan your meals ahead of time</b>: Planning your meals in advance can save you time and money. Take some time each week to plan out your meals for the upcoming week. Create a shopping list based on the ingredients you need for those meals, and stick to it when you go grocery shopping.</li>
                        <br/>
                       <li><b>Buy in bulk</b>: Buying in bulk can save you money in the long run. Look for deals on bulk items such as grains, nuts, and seeds. You can also buy frozen vegetables and fruits in bulk, which are often cheaper than fresh produce.</li>
                <br/>
                        <li> <b>Cook at home</b>: Eating out can be expensive and often unhealthy. Cooking at home is a great way to save money and ensure that you are eating healthy. Look for simple, healthy recipes that use affordable ingredients.</li>
            <br/>
                        <li> <b>Use seasonal produce</b>: Seasonal produce is often cheaper and fresher than out-of-season produce. Look for fruits and vegetables that are in season and build your meals around them.
        </li>
    <br/>
                        <li> <b>Shop sales and use coupons</b>: Take advantage of sales and coupons to save money on healthy foods. Look for deals on items like canned beans, whole grains, and lean protein sources.</li>
                        <br/>
                        <li><b>Eat plant-based meals</b>: Meat can be expensive, so try incorporating more plant-based meals into your diet. Legumes, lentils, and tofu are affordable sources of protein.</li>
                        <br/>
                        <li> <b>Don't waste food</b>: Wasting food is wasting money. Plan your meals based on what you already have in your fridge and pantry. Use leftovers for lunch the next day or freeze them for later.
                        </li>
                        <br/>
                    </div>

                    <div className="text"> Eating healthy on a budget is possible with a little planning and some smart shopping. At First Lithonia Medical Center, we believe that healthy eating is essential for overall health and well-being. Start incorporating these tips into your daily routine and see the difference in your health and your wallet.</div>
                    <title>black nutrition doctor</title>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">
                            <title>black nutrition doctor</title>
                            <div className="prev-post">
                                <h5><a href="/blog-post-smart-snacking"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-breast-cancer-awareness">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default BudgetEating;