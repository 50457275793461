import React from "react";
import "./band.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'


function Band() {
   return(
           <section id="band">
               <div className="band">
                   <title>family doctor</title>
                   <div className="item">
                       <title>family doctor</title>
                       <div className="icon">
                           <title>family doctor</title>
                           <FontAwesomeIcon icon={solid('briefcase-medical')}/>
                       </div>
                       <h1>20</h1>
                       <p>years of experience</p>
                   </div>
                   <div className="item">
                       <title>family doctor</title>
                       <div className="icon">
                           <title>family doctor</title>
                           <FontAwesomeIcon icon={solid('hand-holding-medical')}/>
                       </div>
                       <h1>3</h1>
                       <p>medical experts</p>
                   </div>
                   <div className="item">
                       <title>family doctor</title>
                       <div className="icon">
                           <FontAwesomeIcon icon={solid('house-chimney-medical')}/>
                       </div>
                       <h1>7</h1>
                       <p>office exam rooms</p>
                   </div>
                   <div className="item">
                       <title>family doctor</title>
                       <div className="icon">
                           <title>family doctor</title>
                           <FontAwesomeIcon icon={solid('bed-pulse')}/>
                       </div>
                       <h1>15,000+</h1>
                       <p>happy patients</p>
                   </div>
               </div>
           </section>

   );
}

export default Band;
