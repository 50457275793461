import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./aboutUsC.css";
import foundationmain from '../assets/images/foundation/dr-isioma-okobah-foundation-2017.JPG'

function AboutUsOkobah() {
  return (
    <section className="main-about-us">
      <Container>
        <Row className="d-flex align-items-center">
          <Col lg={6}>
            <div className="about-img-box">
              <img
                src={foundationmain}
                alt="dr isioma okobah foundation"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="about-content">
              <title> Rural Healthcare</title>
              <span className="sub-title">ABOUT US</span>
              <h2 className="h2-title">Quality Healthcare For The Underserved</h2>
              <div className="about-text">
                <p>
                  In 2007, Dr. Okobah decided she could make even more
                  impact on the world by creating a non-governmental and non-profit
                  making organization focused on promoting the individual well being
                  of the rural populace. Each year, the Dr. Isioma Okobah Foundation
                  hosts medical outreaches and other initiatives in the poorest
                  regions of Nigeria. Dr. Okobah's mission has always been to assist
                  the less privileged, the physically challenged, empower widows, and
                  improve the health needs of the rural dwellers.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

  );
}

export default AboutUsOkobah;
