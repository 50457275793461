export const FAQ = [
    {
        q: "ARE ALL YOUR PROVIDERS BOARD CERTIFIED?",
        a: "YES! Here at First Lithonia Medical Center we understand that when it comes to receiving medical treatment or general health care, you want a highly qualified doctor dedicated to providing outstanding care. When you choose a physician or nurse practitioner that is board certified, you can be confident that he or she is skilled and knowledgeable. We believe in the highest standards, that’s why we ensure all our providers are not only the best at what they do, but also board certified."
    },
    {
        q: "HOW LONG WILL IT TAKE ME TO GET AN APPOINTMENT?",
        a: "You don’t even need an appointment at our medical facility. You can walk-in and have your needs addressed. However, if you do need an appointment, same-day appointments are available. You can call, text, or book online for any acute and non-acute visits, including physicals, chronic disease follow-ups, and medication refills.  We accommodate walk-ins from 8am-11:45am in the mornings and from 2pm - 3:45pm."
    },
    {
        q: "HOW DO I GET IN CONTACT WITH SOMEONE IN AN EMERGENCY?",
        a: "If it is an emergency, please call 911 or go to a local emergency room. For non-emergency issues, you can call us during our office hours at 770-323-6458. After hours, you can send a text message at 770-323-6458."
    },
    {
        q: "WHAT INSURANCE PLANS DO YOU ACCEPT?",
        a: "We accept all medicare and medicaid plans and most commercial insurance plans. For a more accurate answer, please contact your insurance carrier or our billing department. Below is a basic list of the insurance plans we currently accept: \n \n" +
            "Medicare: Aetna Medicare Advantage, Allwell Medicare Advantage, Anthem Medicare Advantage, Cigna Health Springs, Clover Health, Humana Medicare Advantage, Medicare Part B of Georgia, Sonder Health Plans, United Healthcare Medicare Advantage, Wellcare Medicare Advantage\n \n" +
            "Commercial Insurance: Aetna, Emblem Health, All Savers, First Health Network, Allied benefits, Fringe Benefit Group, Allied National, GEHA, Auxiant, Golden Rule, Benefit Administrative Services, Humana, Bind, Blue Cross Anthem, Southern Guaranty Insurance Group, ChampVA, Tricare, Cigna (Except Local Plus Plans), UMR, EBMS, United Health One, United Healthcare, Web TPA, The Health Plan\n \n" +
            "ObamaCare: Ambetter (Except Georgia Select Plans), Friday Health Plan, Oscar Health Plan\n\n" +
            "Medicaid: Amerigroup, Caresource, Peachstate, Georgia Department of Community Health (Medicaid)\n\n"
    },
    {
        q: "WHAT DO I BRING TO MY APPOINTMENT?",
        a: "Bring your ID, insurance Card, and a list of current medications you are taking."
    },
    {
        q: "WHAT ARE YOUR HOURS OF OPERATION?",
        a: "Monday-Friday 8am-5pm (lunch 1pm-2pm), every other Saturday 9am-2pm"
    },
    {
        q: "WHEN SHOULD I HAVE A COMPLETE PHYSICAL AND WILL IT BE COVERED BY MY INSURANCE",
        a: "The providers at First Lithonia Medical Center highly encourage our patients to complete a comprehensive physical once a year. Most insurances offer a FREE annual physical, however this benefit varies between commercial insurance plans and managed care plans. We recommend verifying your benefits prior to your physical. But if it is covered by your insurance carrier, this is a health benefit will be 100% covered, so why not take advantage of it?"

    },
    {
        q: "DO YOU HAVE A LAB IN OFFICE AND WHAT ARE THE HOURS?",
        a: "Yes! We partner with Quest Diagnostics to provide our patients with standard care! Our lab hours are 8am-12:15pm M-F, 2pm-4:15pm M-Thur, 9am-12pm Sat"
    },
    {
        q: "WHAT HOSPITAL AFFILIATIONS DO YOU HAVE?",
        a: "First Lithonia Medical Center is affiliated with the Emory Healthcare Network."
    },
    {
        q: "HOW SOON SHOULD I BE SEEN AFTER HOSPITAL DISCHARGE?",
        a: "Hospitals typical recommend you follow up with your primary care provider immediately after being discharged."
    },
    {
        q: "WHO DO I CALL IF I HAVE A QUESTION ABOUT MY BILL?",
        a: "If any issues regarding billing come into question, feel free to contact our insurance and billing department by calling the office or by sending us a message on the patient portal."
    },
    {
        q: "HOW DO I GET A REFERRAL TO A SPECIALIST?",
        a: "For a referral to a specialist to be created, the patient needs to be evaluated by a provider who will then generate the referral for the patient."
    },
    {
        q: "HOW DO I GET MY LAB RESULTS?",
        a: "You can check your patient portal or ask the office to send you pdf copy using our secure email."
    },
    {
        q: "WHAT DO I DO IF I NEED A REFILL ON MEDICATION?",
        a: "Call 770-323-6458 and choose option 3"
    },
    {
        q: "WHAT DOES PRIMARY CARE INVOLVE?",
        a: "Primary care is a term used to describe the first point of contact between an individual and the healthcare system. It is usually provided by general practitioners (GPs), family doctors, or primary care physicians.\n" +
            "\n" +
            "Primary care involves a range of healthcare services that are focused on the prevention, diagnosis, and treatment of common medical conditions. This may include routine check-ups, health screenings, vaccinations, and management of chronic conditions such as diabetes and hypertension.\n" +
            "\n" +
            "Primary care physicians are also responsible for referring patients to specialists for further evaluation and treatment if needed. They play a vital role in coordinating care between different healthcare providers and ensuring that their patients receive appropriate and timely care.\n" +
            "\n" +
            "Overall, primary care aims to promote health, prevent illness, and manage acute and chronic medical conditions to improve the health outcomes and quality of life of individuals and communities."
    },

]