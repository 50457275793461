import React from "react";
import Portfolio from "../component/portfolio";
import NavBar from "../component/navBar";
import {Col, Container, Row} from "react-bootstrap";
import AboutUsOkobah from "../component/aboutOkobahFoundationC";

function OkobahFoundation() {

  return(
      <div className="main">
        <NavBar />

        <section
            className="main-banner inner-banner about-banner"
            style={{
              height: 100,
            }}
        >
          <Container>
            <Row>
              <Col>
                <div className="banner-content">
                  <h1 className="h1-title">Dr. Isioma Okobah Foundation</h1>
                  <div className="breadcrumb-box">
                    <ul>
                      <li>
                        <a href="/" title="home">
                          Home
                        </a>
                      </li>
                      <li>DR. ISIOMA OKOBAH FOUNDATION</li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
    <AboutUsOkobah/>
    <Portfolio />
  </div>
  );
}

export default OkobahFoundation;
