import React from "react";
import { Nav, Tab, } from 'react-bootstrap';
import opening from '../assets/images/opening-lithonia-medical-center.jpg'
import "./timeline.css"
import family from "../assets/images/first-lithonia-medical-center-family.jpg";
import medicalOutreach from "../assets/images/dr-isioma-okobah-foundation-medical.JPG";
import bethelgodwins from "../assets/images/bethel-godwins-portrait.jpg";
import psych from "../assets/images/lithonia-psychiatrist-psych.jpg"


function Timeline(){
   return(
       <>
           <section className="history-section">
               <div className="auto-container">
                   <title>primary care physician near me</title>
                   <div className="sec-title " >
                       <title>primary care physician near me</title>
                       <div className="title" >OUR JOURNEY</div>
                       <title>primary care physician near me</title>
                   </div>
                   <Tab.Container defaultActiveKey="first" style={{paddingTop:60}}>

                       <Nav className="nav-tabs tab-btn-style-one">

                           <Nav.Item>
                               <span>
                               <Nav.Link eventKey="first">2005</Nav.Link>
                               </span>
                           </Nav.Item>
                           <Nav.Item>
                               <span>
                               <Nav.Link eventKey="second">2007</Nav.Link>
                               </span>
                           </Nav.Item>
                           <Nav.Item>
                               <span>
                               <Nav.Link eventKey="third">2009</Nav.Link>
                               </span>
                           </Nav.Item>
                           <Nav.Item>
                               <span>
                               <Nav.Link eventKey="fourth">2018</Nav.Link>
                               </span>
                           </Nav.Item>
                           <Nav.Item>
                               <span>
                               <Nav.Link eventKey="fifth">2023</Nav.Link>
                               </span>
                           </Nav.Item>
                       </Nav>
                       <Tab.Content>
                           <Tab.Pane eventKey="first" className=" fadeInUp-animated">
                               <div className="row align-items-center">
                                   <title>primary care physician near me</title>
                                   <div className="col-lg-6">
                                       <title>primary care physician near me</title>
                                       <div className="image"><title>primary care physician near me</title><img
                                           src={opening}
                                           alt="grand opening of medical center"/>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <title>primary care physician near me</title>
                                       <div className="content pl-lg-4">
                                           <title>primary care physician near me</title>
                                           <div className="sec-title light">
                                               <title>primary care physician near me</title>
                                               <div className="sub-title"
                                               style={{color:'black'}}>The Beginning</div>
                                               <h2 style={{color:'antiquewhite'}}>First Lithonia Medical Center <br/>Was Born.</h2>
                                           </div>
                                           <div className="text" style={{color:'white'}}>Dr. Isioma Okobah, CEO and founder, originally opened
                                               First Lithonia Medical Center on Snapfinger Woods Drive immediately
                                               following the completion of her Residency at Emory Hospital. Some of
                                               the patients that she cared for during her residency decided to make
                                               First Lithonia Medical Center their PCP office and remain patients
                                               till this day! During Dr. Okobah's time at this location she focused
                                               on providing quality care while she slowly grew the practice. At the
                                               time, this was the only PCP office in the area.
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </Tab.Pane>
                           <Tab.Pane eventKey="second" className=" fadeInUp-animated">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="image"><img
                                           src={family}
                                           alt="first litonia medical center family"/>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="content pl-lg-4">
                                           <div className="sec-title light">
                                               <div className="sub-title" style={{color:'black'}}>A New Home</div>
                                               <h2 style={{color:'antiquewhite'}}>First Lithonia Medical Center <br/>Moves.</h2>
                                           </div>
                                           <div className="text" style={{color:'white'}}>Built on 2 acres of land on Panola Rd, Dr. Okobah had
                                               a vision she wanted to bring to life when she designed and
                                               constructed this state of the art medical center. This new home for
                                               the practice represented the future of medical care in Lithonia.
                                               Combining quality medical care in a family oriented environment
                                               cultivated the lifelong relationships we have with our patients till
                                               this day.
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </Tab.Pane>
                           <Tab.Pane eventKey="third" className=" fadeInUp-animated">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="image"><img
                                           src={medicalOutreach}
                                           alt="dr-isioma-okobah-foundation-medical"/>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="content pl-lg-4">
                                           <div className="sec-title light">
                                               <div className="sub-title" style={{color:'black'}}>Giving Back</div>
                                               <h2 style={{color:'antiquewhite'}}>Dr. Isioma Okobah Foundation <br/>Was Created.</h2>
                                           </div>
                                           <div className="text" style={{color:'white'}}>In 2007, Dr. Okobah decided she could make even more
                                               impact on the world by creating a non-governmental and non-profit
                                               making organization focused on promoting the individual well being
                                               of the rural populace. Each year, the Dr. Isioma Okobah Foundation
                                               hosts medical outreaches and other initiatives in the poorest
                                               regions of Nigeria. Dr. Okobah's mission has always been to assist
                                               the less privileged, the physically challenged, empower widows, and
                                               improve the health needs of the rural dwellers.
                                           </div>
                                           <div className="sec-btn" style={{backgroundColor:"white", }}><a href={'https://www.firstlithonia.com/dr-isioma-okobah-foundations'}>Learn More</a></div>
                                       </div>
                                   </div>
                               </div>
                           </Tab.Pane>
                           <Tab.Pane eventKey="fourth" className=" fadeInUp-animated">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="image"><img
                                           src={bethelgodwins}
                                           alt="bethel-godwins-NP"/>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="content pl-lg-4">
                                           <div className="sec-title light">
                                               <div className="sub-title" style={{color:'black'}}>A New Addition</div>
                                               <h2 style={{color:'antiquewhite'}}>Mrs. Bethel Godwins Joins <br/>First Lithonia Medical Center.
                                               </h2>
                                           </div>
                                           <div className="text" style={{color:'white'}}>As the patient population at First Lithonia Medical
                                               Center grew, we wanted to add a provider that embodied our
                                               practice's values. Mrs. Bethel was the perfect choice and she
                                               quickly became a part of the family. The patients love her and value her medical expertise!
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </Tab.Pane>
                           <Tab.Pane eventKey="fifth" className=" fadeInUp-animated">
                               <div className="row align-items-center">
                                   <div className="col-lg-6">
                                       <div className="image"><img
                                           src={psych}
                                           alt="lithonia-psych-doctor"/>
                                       </div>
                                   </div>
                                   <div className="col-lg-6">
                                       <div className="content pl-lg-4">
                                           <div className="sec-title light">
                                               <div className="sub-title" style={{color:'black'}}>Psychiatry</div>
                                               <h2 style={{color:'antiquewhite'}}>We Added Psychiatry <br/>Services.</h2>
                                           </div>
                                           <div className="text" style={{color:'white'}}> To meet the needs of our patients we decided to add psychiatry services to our expanding medical center.
                                               Our board certified medical doctor, Dr. Isioma Okobah will start seeing patients for psychiatric conditions as of January 2023. Prioritizing mental health is extremley important to First Lithonia Medical Center and we are excited to start providing care in this field.
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </Tab.Pane>
                       </Tab.Content>
                   </Tab.Container>
               </div>
           </section>
       </>

   )
}
export default Timeline;