import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../component/navBar";
import NavBarBottom from "../../component/navbarBottom";
import Footer from "../../component/footer";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import geriatrichealth from "../../assets/images/geriatric-health-services.jpg";



function GeriatricHealth() {
        return (

            <div className="main">
                <NavBar />

                <section
                    className="main-banner inner-banner about-banner"
                    style={{
                        height: 250,
                    }}
                >
                    <Container>
                        <Row>
                            <Col>
                                <div className="banner-content">
                                    <h1 className="h1-title">Our Services</h1>
                                    <div className="breadcrumb-box">
                                        <ul>
                                            <li>
                                                <a href="/" title="home">
                                                    Home
                                                </a>
                                            </li>
                                            <li>GERIATRIC HEALTH</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* <!-- Sidebar Page Container --> */}
                <div class="sidebar-page-container">
                    <div class="auto-container">
                        <div class="row">
                            <div class="col-lg-4">
                                <aside class="sidebar">
                                    {/* <!-- Category Widget --> */}
                                    <div class="sidebar-widget-two categories-widget-two">
                                        <div class="widget-content">
                                            <h8 className="heading-8"> Our Services Include:</h8>
                                            <ul>
                                                <li><ArrowRightIcon/>Annual physicals</li>
                                                <li><ArrowRightIcon/>Lab tests</li>
                                                <li><ArrowRightIcon/>Chronic disease management</li>
                                                <li><ArrowRightIcon/>Colon cancer testing</li>
                                                <li><ArrowRightIcon/>Osteoporosis screening</li>
                                                <li><ArrowRightIcon/>Diagnostic testing evaluations</li>
                                                <li><ArrowRightIcon/>Smoking cessation</li>
                                                <li><ArrowRightIcon/>Prostate Cancer Testing</li>
                                                <li><ArrowRightIcon/>Depression Screening</li>
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>

                            <div className="col-lg-8">
                                <div className="services-details pl-lg-5">
                                    <div className="image-box"><img
                                        src={geriatrichealth}
                                        alt="geriatric health"
                                        style={{height:400, marginTop:-40}}/>
                                    </div>
                                    <div className="content">
                                        <div className="sec-title">
                                            <div className="sub-title">Geriatric Health</div>
                                            <h2>Specialized, Quality Care<br/> For Our Aging Patients.</h2>
                                        </div>
                                        <div className="text">Promoting health and preventing disease in older Americans is key to the health of the nation. Physical activity can be especially important for older adults because it can improve daily quality of life, physical function, and the ability to perform activities of daily living.
                                            Many of us will experience unique health conditions and concerns as we grow older. Our healthcare professionals are trained to help you manage your well-being so you can continue to maintain health and independence for as long as possible. We focus on risk assessments that ask the important questions
                                            to determine the best way to manage your care.
                                        </div>
                                        <blockquote>
                                            <div className="text" style={{fontSize:20}}><b>"</b>Aging is an extraordinary process where you become the person you always should have been.<b>"</b>
                                            </div>
                                            <h4> —David Bowie</h4>
                                        </blockquote>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NavBarBottom/>
                <Footer />
            </div>

        );
    }


export default GeriatricHealth;