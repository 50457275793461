import React from "react";
import NavBar from "../component/navBar";
import NavBarBottom from "../component/navbarBottom";
import {Col, Container, Row} from "react-bootstrap";
import Footer from "../component/footer";
import Pricing from "../component/pricing";

function Insurances() {
  return (
  <div className="main">
    <NavBar />

    <section
        className="main-banner inner-banner about-banner"
        style={{
          height: 250,
        }}
    >
      <Container>
        <Row>
          <Col>
            <div className="banner-content">
              <h1 className="h1-title">Insurances</h1>
              <div className="breadcrumb-box">
                <ul>
                  <li>
                    <a href="/" title="home">
                      Home
                    </a>
                  </li>
                  <li>INSURANCES</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
    <Pricing />
    <NavBarBottom/>
    <Footer />
  </div>
);
    }

export default Insurances;
