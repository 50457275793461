import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import heartattack from "../../assets/images/blog/chronicdiseases/heart-attack-first-lithonia-medical-center.jpg";
import "./blogdetails.css";
import "../blog.css"



function HeartAttack () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog"><img src={heartattack} alt="lithonia-heart-doctor"/></div>

                    <div className="sec-title">
                        <h1>What Is A Heart Attack?
                        </h1>
                    </div>
                    <div className="text">A heart attack, sometimes referred to as a myocardial infarction, occurs when the blood flow that normally carries oxygen to a portion of your heart muscle is abruptly interrupted. You're not getting enough oxygen to your heart. The heart muscle will start to deteriorate if blood flow is not soon restored. Heart attacks are a common occurrence and according to centers for disease control and prevention, more than 800,000 people in the United States suffer from a heart attack each year. It is important to note that a heart attack and a cardiac arrest are not the same, as a cardiac arrest is when the heart suddenly stops beating. When this occurs, blood stops flowing to vital organs in the body. Certain arrhythmias that stop the heart from pumping blood can result in cardiac arrests. The risk of having a heart attack depends on certain factors like your age, your lifestyle habits and other medical conditions. Many people who have had suffered from a heart attack go on to live their lives fully. Call 9-1-1 as soon as you suspect a heart attack is occurring, whether it's for you or someone else as quick action can save your life or the life of others. The more time the heart spends without enough oxygen, the more harm the heart muscle sustains. It is important to know the difference between chest pain from stable angina and chest pain from a heart attack, if you are unable to identify the difference call 9-1-1 immediately. Not all heart attacks start off with the sharp, severe chest pain that occurs when the heart's blood supply is cut off. Heart attacks can happen silently and can occur with little or no symptoms shown; this is known as the silent heart attack. Symptoms of a heart attack may vary from person to person and can vary between genders. Symptoms of a heart attack include:  <br/>  <br/>

                        <li><b>Chest pain or discomfort in the center or left side of the chest</b></li>
                        <li><b>Shortness of breath from little physical activity</b></li>
                        <li><b>Pain or discomfort in either one arm or both</b></li>
                        <li><b>Sweating profusely for no reason</b></li>
                        <li><b>Rapid or abnormal heart beat</b></li>
                        <li><b>Feeling unusually tired for no reason for days</b></li>
                    </div>

                    <div className="text"> Upon arriving at the hospital after calling 911 an EKG (electrocardiogram) is the most common initial test for a heart attack and may be given upon minutes of your arrival. Your doctor may decide to perform a physical examination, inquire about your medical history, and request other testing based on the results of the EKG. Heart muscle cells die during a heart attack and release proteins into your blood. The concentration of these proteins in your blood can be determined by blood testing. A chest X-ray or computed tomography (CT) scans are imaging procedures that help your doctor determine if your heart is functioning normally. A stress test may also be necessary to assist your doctor identify the extent of the damage to your heart or whether coronary artery disease is to blame for the attack. </div>
                    <div className="text">Coronary artery disease is the most common cause of a heart attack. This occurs when the coronary arteries are unable to provide your heart muscle with adequate oxygen-rich blood. The majority of the time, coronary artery disease develops when plaque, a waxy material, accumulates inside your arteries and narrows them. Inside your artery, a plaque layer may eventually break open. Blood clots start to develop on the plaque's surface as a result of this. Your heart may not receive enough blood if the clot grows large enough. Untreated blockages cause a portion of your heart muscle to start dying.</div>
                    <div className="text">There are certain risk factors that make it more likely to develop a coronary artery disease and having a heart attack. Having lifestyle habits like smoking, eating unhealthy, barely engaging in physical activity can increase your chances of developing a coronary artery disease. Medical conditions like high blood pressure, high cholesterol, diabetes, obesity can also increase your risk factor. Furthermore the risk of heart disease increases for men after the age of 45 and increases after the age of 55 for women. Family history of early heart disease is also a risk factor, if your mother, sister, or any of your parents' fathers or brothers had coronary artery disease before the age of 55 or before the age of 65, you are at a greater risk of having a heart disease. Eating heart-healthy food, regular exercise, stopping smoking, stress management, and keeping a healthy weight are all examples of healthy lifestyle modifications that can help avoid heart disease. These adjustments can reduce your risk of a heart attack even if you currently have coronary artery disease. </div>
                    <div className="text">Speak with one of the providers at First Lithonia Medical Center about whether taking aspirin can help you prevent blood clots that can cause a heart attack. Having a heart attack increases your chances of having another one. Your doctor could suggest medications or advise you on actions you can take, such as making lifestyle changes that are good for your heart. For people recovering from heart issues, there is a program called cardiac rehabilitation that is overseen by a doctor. To reduce your chance of developing further heart and blood vessel disorders, cardiac rehabilitation entails making heart-healthy lifestyle modifications. These programs include fitness training, heart-healthy living education, and stress - management counseling to assist you in making lifestyle changes.
                    </div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="prev-post">
                                <h5><a href="/blog-post-breast-cancer-awareness"> Previous Post </a></h5>
                            </div>

                            <div className="next-post">
                                <h5><a href="/blog-post-coronary-heart-disease">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default HeartAttack ;