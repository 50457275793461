import React from 'react';
import NavBar from "../../component/navBar";
import Footer from "../../component/footer";
import annualphysical from "../../assets/images/blog/annual-physical.jpg";
import "./blogdetails.css";



function BlogPostAnnualPhysical () {
    return (
        <div className="main">
            <NavBar />
            {/* <!-- Portfolio Details --> */}
            <div className="portfolio-details">
                <div className="auto-container">

                    <div className="image-box-blog"><img src={annualphysical} alt="lithonia-annual-physical"/></div>

                    <div className="sec-title">
                        <h1>The Annual Visit That's All About You!</h1>
                        <h2>By: Nikiya Ifill</h2>
                    </div>
                    <div className="text">It's that time of year again where you have to get that yearly check up. If you're a first timer and ask yourself, what is a physical exam? Well, I have your answers. The annual well-check goes by many names — well-check, check-up, preventive care visit, and annual physical. The well check visit focuses on preventative care instead of a sick visit, here's what to expect from an annual check-up.  </div>
                    <div className="text">Besides the usual height, weight and vital signs, you will be able to get most important things done during your visit such as, referrals for your yearly mammogram (for women), colonoscopy and this may include blood tests, which are customized to evidence-based screening guidelines for your age group and sex. This list is not all-inclusive, of course. Depending on your health, your doctor may order additional tests and a complete blood work analysis to be done at the annual exam. This is done to check on things such as cholesterol, blood sugar, kidney/liver function, etc. These are compared to the numbers from the previous year and accepted standards. Remember that an annual well-check is all about you, so it will be tailored to you. </div>
                    <div className="text">I know you may be wondering, well, all of this sounds good, how much will it cost? I'm here to tell you your annual well check up is no cost to you, it is 100% covered by your insurance. Now keep in mind it is covered 100% but if you do have any other concerns relating to being sick, then your visit becomes a sick visit which you’ll probably have a co-pay depending on whatever type of insurance you have. Be sure to call your insurance company or your doctors office if you do have any concerns regarding your co-pay so you know ahead of time.</div>

                    <div className="text"> First Lithonia Medical Center is your go-to medical center for the best preventative healthcare in the Dekalb County, Fulton County, and Rockdale County areas. To schedule an appointment, book on our website or <a href= "tel:7703236458">call</a> / text us at 770 323 6458. </div>
                    <div className="project-post-pagination">
                        <div className="wrapper-box">

                            <div className="next-post">
                                <h5><a href="/blog-post-urgent-care">Next Topic </a></h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default BlogPostAnnualPhysical ;