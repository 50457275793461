import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./banner.css";
import { Carousel } from 'react-bootstrap';
import img9 from '../assets/main-slider/img9.jpg';
import img14 from '../assets/main-slider/img14.jpg';
import img12 from '../assets/main-slider/img12.jpg';
import img13 from '../assets/main-slider/img13.jpg'
import img15 from '../assets/main-slider/img15.jpg';
import img16 from '../assets/main-slider/img16.jpg';
import img17 from "../assets/main-slider/img17.jpg";



function Banner() {
  return (
      <section className="main-banner">
        <Container>
          <Row className="d-flex align-items-center">
            <Col lg={4}>
              <div className="banner-content">
                <title>family doctor</title>
                <span className="wel-span">Lithonia's Best PCP Office</span>
                <h1 className="wel-h1">First Lithonia Medical Center</h1>
                <p className="wel-p1">
                  A black owned primary health care center serving the community since 2005.
                </p>
                <a href="sms:4706480600" className="sec-btn-home">
                  Text Us Now
                </a>
              </div>
            </Col>
            <Col lg={8}>
              <Carousel id= "home">
                <Carousel.Item >

                  <img
                      className="d-block w-100"
                      src={img9}
                      alt="lithonia doctor"

                  />
                </Carousel.Item>

                <Carousel.Item>

                  <img
                      className="d-block w-100"
                      src={img13}
                      alt="conyers doctor"
                  />
                </Carousel.Item>

                <Carousel.Item>

                  <img
                      className="d-block w-100"
                      src={img14}
                      alt="decatur doctor"
                  />


                </Carousel.Item>

                <Carousel.Item>

                  <img
                      className="d-block w-100"
                      src={img17}
                      alt="emory doctor"
                  />
                </Carousel.Item>

                <Carousel.Item>

                  <img
                      className="d-block w-100"
                      src={img12}
                      alt="stonecrest doctor"
                  />


                </Carousel.Item>

                <Carousel.Item>

                  <img
                      className="d-block w-100"
                      src={img15}
                      alt="atlanta doctor"
                  />


                </Carousel.Item>

                <Carousel.Item>

                  <img
                      className="d-block w-100"
                      src={img16}
                      alt="dekalb doctor"
                  />


                </Carousel.Item>

              </Carousel>
            </Col>
          </Row>
        </Container>
      </section>
  );
}
export default Banner;
