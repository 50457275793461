import React, { Component } from "react";
import Slider from "react-slick";


export default class MultipleItems extends Component {
  render() {
    const settings = {
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1.5,
          },
        },
      ],
    };
    return (
      <div>
        <Slider {...settings}>
          <div>
            <title>pcp near me</title>
            <div className="team-box">
              <title>pcp okobah</title>
              <div className="team-text">
                <title>pcp okobah</title>
                <div className="team-img back-img"
                     style={{
                       backgroundImage:
                           "url('https://i.ibb.co/h8Bjkvs/okobah.jpg')",
                     }}>
                  <title>pcp okobah</title>
                </div>
              </div>
              <div className="team-text-box">
                <title>pcp okobah</title>
                <p>MD, MPH, FAAFP</p>
                <h3 className="h3-title">Isioma Okobah</h3>
              </div>
            </div>
          </div>

          {/* 2 */}

          <div>
            <title>pcp godwins</title>
            <div className="team-box">
              <title>pcp godwins</title>
              <div className="team-text">
                <title>pcp godwins</title>
                <div
                  className="team-img back-img"
                  style={{
                    backgroundImage:
                        "url('https://i.ibb.co/f2qRCRY/godwins.jpg')",
                  }}
                ><title>pcp godwins</title></div>
              </div>
              <div className="team-text-box">
                <title>pcp godwins</title>
                <p>MSN, FNP-C</p>
                <h3 className="h3-title">Bethel Godwins</h3>
              </div>
            </div>
          </div>
          {/* 1 */}
          <div>
            <title>pcp chigni</title>
            <div className="team-box">
              <title>pcp chigni</title>
              <div className="team-text">
                <title>pcp chigni</title>
                <div
                  className="team-img back-img"
                  style={{
                    backgroundImage:
                        "url('https://i.ibb.co/rszWHx5/chigni.jpg')",
                  }}
                ><title>pcp chigni</title></div>
              </div>
              <div className="team-text-box">
                <title>pcp chigni</title>
                <p>MSN, FNP-C</p>
                <h3 className="h3-title">Adamou Chigni</h3>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
}
